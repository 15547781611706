import {
  faArrowAltCircleLeft,
  faClipboardCheck,
  faEdit,
  faExclamation,
  faFile,
  faFileUpload,
  faSync,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { currencyFormat } from "../../../utils/currency";
import AddAccount from "../components/accounts/addAccount/AddAccount";
import { getAccounts, fetchAccountSync, deleteAccount } from "../fetch/fetch_accounts";
import PageTemplate from "../layout/PageTemplate";

export default function Accounts() {
  const [isadd, setisadd] = useState(false);
  const [isupdate, setisupdate] = useState(false);
  const [accountupdate, setaccountupdate] = useState(false);
  const [accounts, setaccounts] = useState([]);

  useEffect(() => {
    const init = async () => {
      const response = await getAccounts();
      if (response.status === 200) {
        const json = await response.json();
        setaccounts(json);
      }
    };
    init();
  }, []);

  const handleSave = (newAccount) => {
    setaccounts([...accounts, newAccount]);
    setisadd(false);
  };
  const handleUpdate = (i, newAccount) => {
    setaccounts([...accounts, newAccount]);
    setisadd(false);
  };
  const handleSync = async (id) => {
    await fetchAccountSync(id);
  };


  const handleDelete = async (id) => {
    await deleteAccount(id); 
    let aux = accounts.filter(function(a){
      return a.id !== id;
    });
    setaccounts(aux);
  }

  return (
    <PageTemplate title="accounts" account="">
      {(!isadd && !isupdate) && (
        <React.Fragment>
                    <Link
                      to={"/" + "account" + "/amazon/upload"}
                      className="text-white"
                    >
                      <button className="btn btn-secondary text-capitalize">
                        <FontAwesomeIcon
                          icon={faFileUpload}
                          className="mr-2"
                          size="1x"
                        />
                        Upload
                      </button>
                    </Link>
                    <Link
                      to={"/amazon/unmatched"}
                      className="text-white"
                    >
                      <button className="btn btn-secondary text-capitalize ml-2">
                        <FontAwesomeIcon
                          icon={faExclamation}
                          className="mr-2"
                          size="1x"
                        />
                        All Amazon Unmatched
                      </button>
                    </Link>
          <table className="table  text-capitalize table-bordered" style={{marginTop: "1rem"}}>
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Walmart Matched / Walmart Total</th>
                <th>Matched Amount</th>
                {/* <th>Purchases</th> */}
                <th style={{ width: "10em" }}>Actions</th>
                <th className="w-25"></th>
              </tr>
            </thead>
            <tbody>
              {accounts.map((a) => (
                <tr key={a.id} id={a.id}>
                  <td className='text-uppercase'>{a.name}</td>
                  <td>{a?.dashboard?.matched} / {a?.dashboard?.unmatched} </td>
                  <td>{currencyFormat(a?.dashboard?.amount)}</td>
                  {/* <td>{a.purchase}</td> */}
                  <td>
                    <div>
                      <FontAwesomeIcon
                        className="mr-3"
                        icon={faSync}
                        onClick={() => {
                          handleSync(a.id);
                        }}
                      />
                      <FontAwesomeIcon className="ml-2" icon={faEdit} onClick={()=>{
                        setaccountupdate(a);setisupdate(true)
                      }} />
                    </div>
                  </td>
                  <td className="">
                    <Link to={"/" + a.id + "/" + a.name + "/amazon"} className="text-white">
                      <button className="btn btn-secondary text-capitalize mr-3">
                        <FontAwesomeIcon
                          icon={faClipboardCheck}
                          className="mr-2"
                        />
                        Matched
                      </button>
                    </Link>
                    <Link
                      to={"/" + a.id + "/" + a.name + "/walmart/unmatched"}
                      className="text-white"
                    >
                      <button className="btn btn-secondary text-capitalize mr-3 mt-2 ">
                        <FontAwesomeIcon
                          icon={faExclamation}
                          className="mr-2"
                        />
                        Walmart Unmatched
                      </button>
                    </Link>
                    
                      <button onClick={()=> handleDelete(a.id)} className="btn btn-secondary text-capitalize mr-3 mt-2 ">
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="mr-2"
                        />
                        Delete Account
                      </button>
                  
      
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="btn btn-success mt-4"
            onClick={() => setisadd(!isadd)}
          >
            Add
          </button>
        </React.Fragment>
      )}
      {isadd && (
        <React.Fragment>
          <button onClick={() => setisadd(!isadd)}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            <span className="ml-3">Back</span>
          </button>

          <AddAccount onSaved={handleSave} />
        </React.Fragment>
      )}
      {isupdate && (
        <React.Fragment>
          <p onClick={() => setisupdate(!isupdate)}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            <span className="ml-3">Back</span>
          </p>

          <AddAccount onSave={handleSave} account={accountupdate}/>
        </React.Fragment>
      )}
    </PageTemplate>
  );
}
