import React, { useState } from 'react'
import { uploadReport } from '../fetch/amazon_orders'
import PageTemplate from '../layout/PageTemplate'

export default function UploadReport() {

    const [file, setfile] = useState(null)
    const [error, seterror] = useState(null)
    const [feedBack, setFeedback] = useState('')
    const [color, setColor] = useState('')
    const upload = async()=>{
        if(!file)
            return
        const uploadResponse = await uploadReport(file)
        console.log(uploadResponse)
        setFeedback(uploadResponse)
        if(feedBack.state){
            setColor('#5cb85c')
        }else{
            setColor('#d9534f')
        }
    }

    const handleChange = (e)=>{
        const file = e.target.files[0]
        setfile(file)
        seterror(null)
        return
        /*if(file.type === 'text/tab-separated-values'){
            if(file.size>18000000){
                seterror("file is large")
                return    
            }
            setfile(file)
            seterror(null)
            return
        }
        seterror("file format must tsv")*/
    }


    return (
        <PageTemplate title='upload report' account="">
            <div>
                <input type="file" name="report" id="" onChange={handleChange}/>
            </div>
            
            <button className='btn btn-success mt-4' onClick={()=>upload()}>
                Upload file
            </button>

            <br/>
            {feedBack&&
                <p style={{"color": color}}>
                    {feedBack.message}
                </p>
            }

            {error&&
            <p className='error'>
                {error}
            </p>    
            }


        </PageTemplate>
    )
}
