import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse} from "@material-ui/core";
import React, { useEffect } from "react";
import '../OrderTable/OrderTableRow.sass';
import {faAmazon} from "@fortawesome/free-brands-svg-icons";
import {formatDate, formatTimeStamp} from "../../../../utils/date/formatDates";
import IconCollapse from "../IconCollapse/IconCollapse";
import IconWalmart from "../walmart/IconWalmart/IconWalmart";
import AmazonDetail from "../amazon/AmazonDetail/AmazonDetail";
import WalmartDetail from "../walmart/WalmartDetail/WalmartDetail";
import { currencyFormat } from "../../../../utils/currency";

export const UnmatchedOrderTableRow = (props) => {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const iconSize = "1em";
    const iconSizeMd = "2em";
    const totalWalmart = row?.summary?.total   ||  0

    return (
        <div className="order-row__container text-capitalize">
            <ul className="d-flex">
                <li className="order-row__down-item">
                    <IconCollapse open={open} onOpen={() => setOpen(!open)}/>
                </li>
                <li className="mr-3">
                    <IconWalmart size={iconSizeMd}/>
                </li>
                <li className="font-14">{row['_id']}</li>
                <li className="order-row__date">{row.receivedDate}</li>
                <li className="ml-auto">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <IconWalmart size={iconSize}/>
                                </td>
                                <td className='text-right'>
                                    <span className="ml-1 font-16 font-weight-lighter">
                                    {currencyFormat(totalWalmart)}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="d-block font-weight-lighter"/>
                </li>
                <li/>
                <li className='text-right'>
                    <span className="text-success font-20 font-weight-bold">
                    {currencyFormat(totalWalmart)}
                        </span>
                </li>
            </ul>
            <Collapse className="pl-3 " in={open} timeout="auto">
                <div className="row col-12 bg-white my-3 p-0 ml-auto mr-3">
                    <div className="col-8 px-4 py-3 pl-5">
                        <div className="mb-3">
                            <p>
                                <span className="d-block font-weight-bold font-20">
                                <IconWalmart size="1em" className="mr-3"/>
                                    Walmart 
                                </span>
                            </p>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Order Id:</td>
                                        <td>{row['_id']}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer:</td>
                                        <td>{row?.customer}</td>
                                    </tr>
                                    <tr>
                                        <td>Status:</td>
                                        <td>{row?.status}</td>
                                    </tr>
                                    <tr>
                                        <td>Received Date:</td>
                                        <td>{row?.receivedDate}</td>
                                    </tr>
                                    <tr>
                                        <td>State:</td>
                                        <td>{row?.address?.state}</td>
                                    </tr>
                                    <tr>
                                        <td>City:</td>
                                        <td>{row?.address?.city}</td>
                                    </tr>
                                    <tr>
                                        <td>Tracking Number:</td>
                                        <td>{row?.orderTrackingNumber}</td>
                                    </tr>
                                    <tr>
                                        <td>Card Number:</td>
                                        <td>{row?.orderCardNumbers}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col">
                        <WalmartDetail row={row}/>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};
