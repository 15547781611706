import React from "react";

export default function Pagination({
  totalElements,
  totalPages,
  page,
  onNext,
  onPrev,
  onSelect,
}) {
  return (
    <nav aria-label="Page navigation" className="mt-3">
      <ul className="pagination">
        {page > 1 && (
          <React.Fragment>
            <li className="page-item" onClick={onPrev}>
              <span className="page-link">Previous</span>
            </li>

            <li className="page-item ">
              <span className="page-link" onClick={() => onSelect(page - 1)}>
                {page - 1}
              </span>
            </li>
          </React.Fragment>
        )}

        {totalPages - 1 !== page && (
          <React.Fragment>
            <li className="page-item active" onClick={() => null}>
              <span className="page-link">{page}</span>
            </li>
            <li className="page-item">
              <span className="page-link" onClick={() => onSelect(page + 1)}>
                {page + 1}
              </span>
            </li>
            <li className="page-item">
              <span className="page-link" onClick={() => onSelect(page + 2)}>
                {page + 2}
              </span>
            </li>
            <li className="page-item ">
              <span className="page-link" onClick={() => onSelect(page + 3)}>
                {page + 3}
              </span>
            </li>
          </React.Fragment>
        )}

        <li className="page-item">
          <span className="page-link" onClick={() => onSelect(totalPages - 1)}>
            {totalPages - 1}
          </span>
        </li>

        <li className="page-item" onClick={onNext}>
          <span className="page-link">Next</span>
        </li>
      </ul>
    </nav>
  );
}
