const options = {year: 'numeric', month: 'short', day: 'numeric'};
export const formatDate = (date) => {
    return new Date(Date(date)).toLocaleDateString("en-US", options);
};

export const formatTimeStamp = (date) => {
    var d = new Date(date);
    return  d.getFullYear() + '-' + (d.getMonth()+1) + '-' + d.getDate();
}


