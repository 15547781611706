import * as actionTypes from "../action";

const initialState = {
  topbar: true,
  footer: true,
  loginpage: false,
  login: false,
  user: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TOPBAR:
      return {
        ...state,
        top_bar: !state.topbar,
      };
    case actionTypes.FOOTER:
      return {
        ...state,
        footer: !state.footer,
      };
    case actionTypes.LOGINPAGE:
      return {
        ...state,
        loginpage: !state.loginpage,
      };
    case actionTypes.LOGIN:
      return {
        ...state,
        login: action.value,
      };

    case actionTypes.USER:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
