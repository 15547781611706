import React, { useEffect, useState } from "react";
import TableCollapse from "../../TableCollapse/TableCollapse";

import AmazonCharges from "../AmazonCharges/AmazonCharges";
import AmazonFees from "../AmazonFees/AmazonFees";
import DetailItem from "../DetailItem/DetailItem";

export default function AmazonDetail({row}) { 
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0); 
    useEffect(()=>{
        let totalFees = document.getElementById('total-fees').textContent  
        let totalCharges = document.getElementById('total-charges').textContent
        totalFees = parseFloat(totalFees.replace('$', '').trim())
        totalCharges = parseFloat(totalCharges.replace('$', '').trim())
        setSubTotal(totalCharges + totalFees*(-1))
        setTotal(row?.financialSummary?.itemPrice + subTotal);
    }, [])
    return (
        <table className="table m-0 table-sm table-condensed table-borderless text-right text-capitalize">
            <colgroup>
                <col style={{width: "70%"}}/>
                <col style={{width: "20%"}}/>
            </colgroup>
            <tbody>
            <TableCollapse
                name="orders"
                total={/*row?.total?.amount*/ total}
                startOpen={true}
            >
                <AmazonCharges charges={row?.financialEvents?.ItemChargeList?.ChargeComponent}/>
                <AmazonFees fees={/*row?.financialSummary?.fees*/ row?.financialEvents?.ItemFeeList?.FeeComponent}/>
                <DetailItem name="subtotal" value={subTotal}/>
            </TableCollapse>
            </tbody>
        </table>
    );
}
