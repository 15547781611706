import React, {useState} from "react";
import { saveEmails } from "../../../fetch/walmart_emails";
import IconWalmart from "../../walmart/IconWalmart/IconWalmart";

export default function FormWalmartSetting({
  emails,
  setemail,
  onAddEmais,
  onPopEmail,
}) {


  const onSave = async () => {
    const response = await saveEmails(emails[0].address, emails[0].password);  
    console.log(response); 
  }

  return (
    <section className="col-6">
      <header className="d-flex">
        <h2>Walmart</h2>
        <div>
          <IconWalmart size={"2em"} />

        </div>
      </header>
      {emails.map(( e, i ) => (
        <div className="d-flex" key={i}>
          <div className="input-section">
            <label htmlFor="email" className="d-block">
              email
            </label>
            <input
              type="email"
              id="email"
              value={e.address}
              onChange={(event) => {
                setemail(i,event.target.value, null);
              }}
              className="form-control input"
            />
          </div>

          <div className="input-section ml-4">
            <label htmlFor="secret" className="d-block">
              password
            </label>
            <input
              type="password"
              id="secret"
              className="form-control input"
              value={e.password}
              onChange={(event) => {
                setemail(i,null, event.target.value);
              }}
            />
          </div>
          <button className='btn' onClick={()=>onPopEmail(i)}>
                -
          </button>
        </div>
      ))}
      <div>
        <button className="mt-5 btn btn-info" onClick={() => onAddEmais()}>
          Add email
        </button>
      </div>
    </section>
  );
}
