import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';

class Icons_fontawesome extends Component {

    render() {
        return (
            <AUX>

                <div className="wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="btn-group pull-right">
                                        <ol className="breadcrumb hide-phone p-0 m-0">
                                            <li className="breadcrumb-item">
                                                <Link to="#">Icons</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Font Awesome</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Font Awesome</h4>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">

                                        <h4 className="mt-0 header-title">Examples</h4>
                                        <p className="text-muted m-b-30 font-14">Use<code>&lt;i className="fa
                                            fa-font-awesome"&gt;&lt;/i&gt;</code>.
                                        </p>

                                        <section>
                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-address-book"></i>fa fa-address-book
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-address-book-o"></i>fa fa-address-book-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-address-card"></i>fa fa-address-card
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-address-card-o"></i>fa fa-address-card-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bandcamp"></i>fa fa-bandcamp
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bath"></i>fa fa-bath
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bathtub"></i>fa fa-bathtub
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-drivers-license"></i>fa fa-drivers-license
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-drivers-license-o"></i>fa fa-drivers-license-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eercast"></i>fa fa-eercast
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-envelope-open"></i>fa fa-envelope-open
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-envelope-open-o"></i>fa fa-envelope-open-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-etsy"></i>fa fa-etsy
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-free-code-camp"></i>fa fa-free-code-camp
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-grav"></i>fa fa-grav
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-handshake-o"></i>fa fa-handshake-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-id-badge"></i>fa fa-id-badge
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-id-card"></i>fa fa-id-card
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-id-card-o"></i>fa fa-id-card-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-imdb"></i>fa fa-imdb
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-linode"></i>
                                                    fa fa-linode
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-meetup"></i>fa fa-meetup
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-microchip"></i>fa fa-microchip
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-podcast"></i>fa fa-podcast
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-quora"></i>fa fa-quora
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ravelry"></i>fa fa-ravelry
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-s15"></i>fa fa-s15
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-shower"></i>fa fa-shower
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-snowflake-o"></i>fa fa-snowflake-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-superpowers"></i>fa fa-superpowers
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-telegram"></i>fa fa-telegram
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer"></i>fa fa-thermometer
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-0"></i>fa fa-thermometer-0
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-1"></i>fa fa-thermometer-1
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-2"></i>fa fa-thermometer-2
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-3"></i>fa fa-thermometer-3
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-4"></i>fa fa-thermometer-4
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-empty"></i>fa fa-thermometer-empty
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-full"></i>fa fa-thermometer-full
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-half"></i>fa fa-thermometer-half
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-quarter"></i>fa
                                                    fa-thermometer-quarter
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thermometer-three-quarters"></i>fa
                                                    fa-thermometer-three-quarters
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-times-rectangle"></i>fa fa-times-rectangle
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-times-rectangle-o"></i>fa fa-times-rectangle-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user-circle"></i>fa fa-user-circle
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user-circle-o"></i>fa fa-user-circle-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user-o"></i>fa fa-user-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-vcard"></i>fa fa-vcard
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-vcard-o"></i>fa fa-vcard-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-window-close"></i>fa fa-window-close
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-window-close-o"></i>fa fa-window-close-o
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-window-maximize"></i>fa fa-window-maximize
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-window-minimize"></i>fa fa-window-minimize
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-window-restore"></i>fa fa-window-restore
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wpexplorer"></i>fa fa-wpexplorer
                                                </div>

                                            </div>
                                        </section>

                                        <section>
                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-american-sign-language-interpreting"></i>fa
                                                    fa-american-sign-language-interpreting
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-asl-interpreting"></i>fa fa-asl-interpreting
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-assistive-listening-systems"></i>fa
                                                    fa-assistive-listening-systems
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-audio-description"></i>fa fa-audio-description
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-blind"></i>fa
                                                    fa-blind
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-braille"></i>fa
                                                    fa-braille
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-deaf"></i>fa
                                                    fa-deaf
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-deafness"></i>fa
                                                    fa-deafness
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-envira"></i>fa
                                                    fa-envira
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-first-order"></i>fa fa-first-order
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-gitlab"></i>fa
                                                    fa-gitlab
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-glide"></i>fa
                                                    fa-glide
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-glide-g"></i>fa
                                                    fa-glide-g
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-hard-of-hearing"></i>fa fa-hard-of-hearing
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-low-vision"></i>fa
                                                    fa-low-vision
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-pied-piper"></i>fa
                                                    fa-pied-piper
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-question-circle-o"></i>fa fa-question-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-sign-language"></i>fa fa-sign-language
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-signing"></i>fa
                                                    fa-signing
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-snapchat"></i>fa
                                                    fa-snapchat
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-snapchat-ghost"></i>fa fa-snapchat-ghost
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-snapchat-square"></i>fa fa-snapchat-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-themeisle"></i>fa
                                                    fa-themeisle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-universal-access"></i>fa fa-universal-access
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-viadeo"></i>fa
                                                    fa-viadeo
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-viadeo-square"></i>fa fa-viadeo-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-volume-control-phone"></i>fa
                                                    fa-volume-control-phone
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-wheelchair-alt"></i>fa fa-wheelchair-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-wpbeginner"></i>fa
                                                    fa-wpbeginner
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-wpforms"></i>fa
                                                    fa-wpforms
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-yoast"></i>fa
                                                    fa-yoast
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-font-awesome"></i>fa fa
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-font-awesome"></i>fa fa-font-awesome
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-google-plus-official"></i>fa
                                                    fa-google-plus-official
                                                </div>

                                            </div>
                                        </section>

                                        <section>
                                            <h4 className="page-header header-title m-b-30">
                                                <b>20 New Icons in 4.5</b>
                                            </h4>
                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-bluetooth"></i>fa
                                                    fa-bluetooth
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-bluetooth-b"></i>fa fa-bluetooth-b
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-codiepie"></i>fa
                                                    fa-codiepie
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-credit-card-alt"></i>fa fa-credit-card-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-edge"></i>fa
                                                    fa-edge
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-fort-awesome"></i>fa fa-fort-awesome
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-hashtag"></i>fa
                                                    fa-hashtag
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-mixcloud"></i>fa
                                                    fa-mixcloud
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-modx"></i>fa
                                                    fa-modx
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-pause-circle"></i>fa fa-pause-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-pause-circle-o"></i>fa fa-pause-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-percent"></i>fa
                                                    fa-percent
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-product-hunt"></i>fa fa-product-hunt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-reddit-alien"></i>fa fa-reddit-alien
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-scribd"></i>fa
                                                    fa-scribd
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-shopping-bag"></i>fa fa-shopping-bag
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-shopping-basket"></i>fa fa-shopping-basket
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-stop-circle"></i>fa fa-stop-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-stop-circle-o"></i>fa fa-stop-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-usb"></i>fa
                                                    fa-usb
                                                </div>

                                            </div>
                                        </section>

                                        <section>
                                            <h4 className="page-header header-title m-b-30">
                                                <b>66 Icons in 4.4</b>
                                            </h4>
                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-500px"></i>fa
                                                    fa-500px
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-amazon"></i>fa
                                                    fa-amazon
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-balance-scale"></i>fa fa-balance-scale
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6"><i
                                                    className="fa fa-battery-0"></i>fa
                                                    fa-battery-0
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-battery-1"></i>
                                                    fa fa-battery-1
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-battery-2"></i>
                                                    fa fa-battery-2
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-battery-3"></i>
                                                    fa fa-battery-3
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-battery-4"></i>
                                                    fa fa-battery-4
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-battery-empty"></i>
                                                    fa fa-battery-empty
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-battery-full"></i>
                                                    fa fa-battery-full
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-battery-half"></i>
                                                    fa fa-battery-half
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-battery-quarter"></i>
                                                    fa fa-battery-quarter
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-battery-three-quarters"></i>
                                                    fa
                                                    fa-battery-three-quarters
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-black-tie"></i>
                                                    fa fa-black-tie
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-calendar-check-o"></i>
                                                    fa fa-calendar-check-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-calendar-minus-o"></i>
                                                    fa fa-calendar-minus-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-calendar-plus-o"></i>
                                                    fa fa-calendar-plus-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-calendar-times-o"></i>
                                                    fa fa-calendar-times-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-cc-diners-club"></i>
                                                    fa fa-cc-diners-club
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cc-jcb"></i>
                                                    fa
                                                    fa-cc-jcb
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-chrome"></i>
                                                    fa
                                                    fa-chrome
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-clone"></i>
                                                    fa
                                                    fa-clone
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-commenting"></i>
                                                    fa fa-commenting
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-commenting-o"></i>
                                                    fa fa-commenting-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-contao"></i>
                                                    fa
                                                    fa-contao
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-creative-commons"></i>
                                                    fa fa-creative-commons
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-expeditedssl"></i>
                                                    fa fa-expeditedssl
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-firefox"></i>
                                                    fa
                                                    fa-firefox
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-fonticons"></i>
                                                    fa fa-fonticons
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-genderless"></i>
                                                    fa fa-genderless
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-get-pocket"></i>
                                                    fa fa-get-pocket
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gg"></i>
                                                    fa
                                                    fa-gg
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gg-circle"></i>
                                                    fa fa-gg-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-grab-o"></i>
                                                    fa fa-hand-grab-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-lizard-o"></i>
                                                    fa fa-hand-lizard-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-paper-o"></i>
                                                    fa fa-hand-paper-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-peace-o"></i>
                                                    fa fa-hand-peace-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-pointer-o"></i>
                                                    fa fa-hand-pointer-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-rock-o"></i>
                                                    fa fa-hand-rock-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-scissors-o"></i>
                                                    fa fa-hand-scissors-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-spock-o"></i>
                                                    fa fa-hand-spock-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-stop-o"></i>
                                                    fa fa-hand-stop-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-hourglass"></i>
                                                    fa fa-hourglass
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-1"></i>
                                                    fa fa-hourglass-1
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-2"></i>
                                                    fa fa-hourglass-2
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-3"></i>
                                                    fa fa-hourglass-3
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-end"></i>
                                                    fa fa-hourglass-end
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-half"></i>
                                                    fa fa-hourglass-half
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-o"></i>
                                                    fa fa-hourglass-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hourglass-start"></i>
                                                    fa fa-hourglass-start
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-houzz"></i>
                                                    fa
                                                    fa-houzz
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-i-cursor"></i>
                                                    fa fa-i-cursor
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-industry"></i>
                                                    fa fa-industry
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-internet-explorer"></i>
                                                    fa fa-internet-explorer
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-map"></i>
                                                    fa
                                                    fa-map
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-map-o"></i>
                                                    fa
                                                    fa-map-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-map-pin"></i>
                                                    fa
                                                    fa-map-pin
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-map-signs"></i>
                                                    fa fa-map-signs
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mouse-pointer"></i>
                                                    fa fa-mouse-pointer
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-object-group"></i>
                                                    fa fa-object-group
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-object-ungroup"></i>
                                                    fa fa-object-ungroup
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-odnoklassniki"></i>
                                                    fa fa-odnoklassniki
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-odnoklassniki-square"></i>
                                                    fa
                                                    fa-odnoklassniki-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-opencart"></i>
                                                    fa fa-opencart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-opera"></i>
                                                    fa
                                                    fa-opera
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-optin-monster"></i>
                                                    fa fa-optin-monster
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-registered"></i>
                                                    fa fa-registered
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-safari"></i>
                                                    fa
                                                    fa-safari
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sticky-note"></i>
                                                    fa fa-sticky-note
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sticky-note-o"></i>
                                                    fa fa-sticky-note-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-television"></i>
                                                    fa fa-television
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-trademark"></i>
                                                    fa fa-trademark
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-tripadvisor"></i>
                                                    fa fa-tripadvisor
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tv"></i>
                                                    fa
                                                    fa-tv
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-vimeo"></i>
                                                    fa
                                                    fa-vimeo
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-wikipedia-w"></i>
                                                    fa fa-wikipedia-w
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-y-combinator"></i>
                                                    fa fa-y-combinator
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-yc"></i>
                                                    fa
                                                    fa-yc
                                                </div>

                                            </div>
                                        </section>

                                        <section>
                                            <h4 className="page-header header-title m-b-30">
                                                <b>40 Icons in 4.3</b>
                                            </h4>

                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bed"></i>
                                                    fa-bed
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-buysellads"></i>
                                                    fa-buysellads
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-cart-arrow-down"></i>
                                                    fa-cart-arrow-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cart-plus"></i>
                                                    fa-cart-plus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-connectdevelop"></i>
                                                    fa-connectdevelop
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-dashcube"></i>
                                                    fa-dashcube
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-diamond"></i>
                                                    fa-diamond
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-facebook-official"></i>
                                                    fa-facebook-official
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-forumbee "></i>
                                                    fa-forumbee
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-heartbeat"></i>
                                                    fa-heartbeat
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bed"></i>
                                                    fa-bed
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-leanpub"></i>
                                                    fa-leanpub
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-mars"></i>
                                                    fa-mars
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mars-double"></i>
                                                    fa-mars-double
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mars-stroke"></i>
                                                    fa-mars-stroke
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mars-stroke-h "></i>
                                                    fa-mars-stroke-h
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mars-stroke-v"></i>
                                                    fa-mars-stroke-v
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-medium "></i>
                                                    fa-medium
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-mercury"></i>
                                                    fa-mercury
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-motorcycle"></i>
                                                    fa-motorcycle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-neuter "></i>
                                                    fa-neuter
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-pinterest-p "></i>
                                                    fa-pinterest-p
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sellsy"></i>
                                                    fa-sellsy
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-server"></i>
                                                    fa-server
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ship"></i>
                                                    fa-ship
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-shirtsinbulk"></i>
                                                    fa-shirtsinbulk
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-simplybuilt"></i>
                                                    fa-simplybuilt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-skyatlas"></i>
                                                    fa-skyatlas
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-street-view"></i>
                                                    fa-street-view
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-subway"></i>
                                                    fa-subway
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-train"></i>
                                                    fa-train
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-transgender"></i>
                                                    fa-transgender
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-transgender-alt "></i>
                                                    fa-transgender-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user-plus"></i>
                                                    fa-user-plus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-user-secret"></i>
                                                    fa-user-secret
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user-times"></i>
                                                    fa-user-times
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-venus"></i>
                                                    fa-venus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-venus-double"></i>
                                                    fa-venus-double
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-venus-mars "></i>
                                                    fa-venus-mars
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-viacoin"></i>
                                                    fa-viacoin
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-whatsapp"></i>
                                                    fa-whatsapp
                                                </div>


                                            </div>
                                        </section>

                                        <section id="new-icons">
                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-angellist"></i>
                                                    fa-angellist
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-area-chart"></i>
                                                    fa-area-chart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-at"></i>
                                                    fa-at
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bell-slash"></i>
                                                    fa-bell-slash
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-bell-slash-o"></i>
                                                    fa-bell-slash-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bicycle"></i>
                                                    fa-bicycle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-binoculars"></i>
                                                    fa-binoculars
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-birthday-cake"></i>
                                                    fa-birthday-cake
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bus"></i>
                                                    fa-bus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-calculator"></i>
                                                    fa-calculator
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cc"></i>
                                                    fa-cc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cc-amex"></i>
                                                    fa-cc-amex
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-cc-discover"></i>
                                                    fa-cc-discover
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-cc-mastercard"></i>
                                                    fa-cc-mastercard
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cc-paypal"></i>
                                                    fa-cc-paypal
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cc-stripe"></i>
                                                    fa-cc-stripe
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cc-visa"></i>
                                                    fa-cc-visa
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-copyright"></i>
                                                    fa-copyright
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eyedropper"></i>
                                                    fa-eyedropper
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-futbol-o"></i>
                                                    fa-futbol-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-google-wallet"></i>
                                                    fa-google-wallet
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ils"></i>
                                                    fa-ils
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ioxhost"></i>
                                                    fa-ioxhost
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-lastfm"></i>
                                                    fa-lastfm
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-lastfm-square"></i>
                                                    fa-lastfm-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-line-chart"></i>
                                                    fa-line-chart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-meanpath"></i>
                                                    fa-meanpath
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-newspaper-o"></i>
                                                    fa-newspaper-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-paint-brush"></i>
                                                    fa-paint-brush
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-paypal"></i>
                                                    fa-paypal
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-pie-chart"></i>
                                                    fa-pie-chart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-plug"></i>
                                                    fa-plug
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-shekel"></i>
                                                    fa-shekel
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sheqel"></i>
                                                    fa-sheqel
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-slideshare"></i>
                                                    fa-slideshare
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-soccer-ball-o"></i>
                                                    fa-soccer-ball-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-toggle-off"></i>
                                                    fa-toggle-off
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-toggle-on"></i>
                                                    fa-toggle-on
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-trash"></i>
                                                    fa-trash
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tty"></i>
                                                    fa-tty
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-twitch"></i>
                                                    fa-twitch
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wifi"></i>
                                                    fa-wifi
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-yelp"></i>
                                                    fa-yelp
                                                </div>

                                            </div>
                                        </section>
                                        <section id="web-application">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Web Application</b>
                                            </h4>
                                            <div className="icon-demo-content row">
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-adjust"></i>
                                                    fa-adjust
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-anchor"></i>
                                                    fa-anchor
                                                </div>
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-archive"></i>
                                                    fa-archive
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows"></i>
                                                    fa-arrows
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows-h"></i>
                                                    fa-arrows-h
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows-v"></i>
                                                    fa-arrows-v
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-asterisk"></i>
                                                    fa-asterisk
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-automobile"></i>
                                                    fa-automobile
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ban"></i>
                                                    fa-ban
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bank"></i>
                                                    fa-bank
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-bar-chart-o"></i>
                                                    fa-bar-chart-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-barcode"></i>
                                                    fa-barcode
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bars"></i>
                                                    fa-bars
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-beer"></i>
                                                    fa-beer
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bell"></i>
                                                    fa-bell
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bell-o"></i>
                                                    fa-bell-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bolt"></i>
                                                    fa-bolt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bomb"></i>
                                                    fa-bomb
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-book"></i>
                                                    fa-book
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bookmark"></i>
                                                    fa-bookmark
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bookmark-o"></i>
                                                    fa-bookmark-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-briefcase"></i>
                                                    fa-briefcase
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bug"></i>
                                                    fa-bug
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-building"></i>
                                                    fa-building
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-building-o"></i>
                                                    fa-building-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bullhorn"></i>
                                                    fa-bullhorn
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bullseye"></i>
                                                    fa-bullseye
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cab"></i>
                                                    fa-cab
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-calendar"></i>
                                                    fa-calendar
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-calendar-o"></i>
                                                    fa-calendar-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-camera"></i>
                                                    fa-camera
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-camera-retro"></i>
                                                    fa-camera-retro
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-car"></i>
                                                    fa-car
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-down"></i>
                                                    fa-caret-square-o-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-left"></i>
                                                    fa-caret-square-o-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-right"></i>
                                                    fa-caret-square-o-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-up"></i>
                                                    fa-caret-square-o-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-certificate"></i>
                                                    fa-certificate
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-check"></i>
                                                    fa-check
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-check-circle"></i>
                                                    fa-check-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-check-circle-o"></i>
                                                    fa-check-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-check-square"></i>
                                                    fa-check-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-check-square-o"></i>
                                                    fa-check-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-child"></i>
                                                    fa-child
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-circle"></i>
                                                    fa-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-circle-o"></i>
                                                    fa-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-circle-o-notch"></i>
                                                    fa-circle-o-notch
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-circle-thin"></i>
                                                    fa-circle-thin
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-clock-o"></i>
                                                    fa-clock-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cloud"></i>
                                                    fa-cloud
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-cloud-download"></i>
                                                    fa-cloud-download
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-cloud-upload"></i>
                                                    fa-cloud-upload
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-code"></i>
                                                    fa-code
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-code-fork"></i>
                                                    fa-code-fork
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-coffee"></i>
                                                    fa-coffee
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cog"></i>
                                                    fa-cog
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cogs"></i>
                                                    fa-cogs
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-comment"></i>
                                                    fa-comment
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-comment-o"></i>
                                                    fa-comment-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-comments"></i>
                                                    fa-comments
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-comments-o"></i>
                                                    fa-comments-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-compass"></i>
                                                    fa-compass
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-credit-card"></i>
                                                    fa-credit-card
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-crop"></i>
                                                    fa-crop
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-crosshairs"></i>
                                                    fa-crosshairs
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cube"></i>
                                                    fa-cube
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cubes"></i>
                                                    fa-cubes
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cutlery"></i>
                                                    fa-cutlery
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-dashboard"></i>
                                                    fa-dashboard
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-database"></i>
                                                    fa-database
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-desktop"></i>
                                                    fa-desktop
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-dot-circle-o"></i>
                                                    fa-dot-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-download"></i>
                                                    fa-download
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-edit"></i>
                                                    fa-edit
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ellipsis-h"></i>
                                                    fa-ellipsis-h
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ellipsis-v"></i>
                                                    fa-ellipsis-v
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-envelope"></i>
                                                    fa-envelope
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-envelope-o"></i>
                                                    fa-envelope-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-envelope-square"></i>
                                                    fa-envelope-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eraser"></i>
                                                    fa-eraser
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-exchange"></i>
                                                    fa-exchange
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-exclamation"></i>
                                                    fa-exclamation
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-exclamation-circle"></i>
                                                    fa-exclamation-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-exclamation-triangle"></i>
                                                    fa-exclamation-triangle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-external-link"></i>
                                                    fa-external-link
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-external-link-square"></i>
                                                    fa-external-link-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eye"></i>
                                                    fa-eye
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eye-slash"></i>
                                                    fa-eye-slash
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-fax"></i>
                                                    fa-fax
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-female"></i>
                                                    fa-female
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-fighter-jet"></i>
                                                    fa-fighter-jet
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-archive-o"></i>
                                                    fa-file-archive-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-audio-o"></i>
                                                    fa-file-audio-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-code-o"></i>
                                                    fa-file-code-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-excel-o"></i>
                                                    fa-file-excel-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-image-o"></i>
                                                    fa-file-image-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-movie-o"></i>
                                                    fa-file-movie-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-pdf-o"></i>
                                                    fa-file-pdf-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-photo-o"></i>
                                                    fa-file-photo-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-picture-o"></i>
                                                    fa-file-picture-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-powerpoint-o"></i>
                                                    fa-file-powerpoint-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-sound-o"></i>
                                                    fa-file-sound-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-video-o"></i>
                                                    fa-file-video-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-word-o"></i>
                                                    fa-file-word-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-zip-o"></i>
                                                    fa-file-zip-o
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-film"></i>
                                                    fa-film
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-filter"></i>
                                                    fa-filter
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-fire"></i>
                                                    fa-fire
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-fire-extinguisher"></i>
                                                    fa-fire-extinguisher
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-flag"></i>
                                                    fa-flag
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-flag-checkered"></i>
                                                    fa-flag-checkered
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-flag-o"></i>
                                                    fa-flag-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-flash"></i>
                                                    fa-flash
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-flask"></i>
                                                    fa-flask
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-folder"></i>
                                                    fa-folder
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-folder-o"></i>
                                                    fa-folder-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-folder-open"></i>
                                                    fa-folder-open
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-folder-open-o"></i>
                                                    fa-folder-open-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-frown-o"></i>
                                                    fa-frown-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gamepad"></i>
                                                    fa-gamepad
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gavel"></i>
                                                    fa-gavel
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gear"></i>
                                                    fa-gear
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gears"></i>
                                                    fa-gears
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gift"></i>
                                                    fa-gift
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-glass"></i>
                                                    fa-glass
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-globe"></i>
                                                    fa-globe
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-graduation-cap"></i>
                                                    fa-graduation-cap
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-group"></i>
                                                    fa-group
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-hdd-o"></i>
                                                    fa-hdd-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-headphones"></i>
                                                    fa-headphones
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-heart"></i>
                                                    fa-heart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-heart-o"></i>
                                                    fa-heart-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-history"></i>
                                                    fa-history
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-home"></i>
                                                    fa-home
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-image"></i>
                                                    fa-image
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-inbox"></i>
                                                    fa-inbox
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-info"></i>
                                                    fa-info
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-info-circle"></i>
                                                    fa-info-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-institution"></i>
                                                    fa-institution
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-key"></i>
                                                    fa-key
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-keyboard-o"></i>
                                                    fa-keyboard-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-language"></i>
                                                    fa-language
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-laptop"></i>
                                                    fa-laptop
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-leaf"></i>
                                                    fa-leaf
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-legal"></i>
                                                    fa-legal
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-lemon-o"></i>
                                                    fa-lemon-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-level-down"></i>
                                                    fa-level-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-level-up"></i>
                                                    fa-level-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-life-bouy"></i>
                                                    fa-life-bouy
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-life-ring"></i>
                                                    fa-life-ring
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-life-saver"></i>
                                                    fa-life-saver
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-lightbulb-o"></i>
                                                    fa-lightbulb-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-location-arrow"></i>
                                                    fa-location-arrow
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-lock"></i>
                                                    fa-lock
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-magic"></i>
                                                    fa-magic
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-magnet"></i>
                                                    fa-magnet
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mail-forward"></i>
                                                    fa-mail-forward
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-mail-reply"></i>
                                                    fa-mail-reply
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mail-reply-all"></i>
                                                    fa-mail-reply-all
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-male"></i>
                                                    fa-male
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-map-marker"></i>
                                                    fa-map-marker
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-meh-o"></i>
                                                    fa-meh-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-microphone"></i>
                                                    fa-microphone
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-microphone-slash"></i>
                                                    fa-microphone-slash
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-minus"></i>
                                                    fa-minus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-minus-circle"></i>
                                                    fa-minus-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-minus-square"></i>
                                                    fa-minus-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-minus-square-o"></i>
                                                    fa-minus-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-mobile"></i>
                                                    fa-mobile
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mobile-phone"></i>
                                                    fa-mobile-phone
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-money"></i>
                                                    fa-money
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-moon-o"></i>
                                                    fa-moon-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-mortar-board"></i>
                                                    fa-mortar-board
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-music"></i>
                                                    fa-music
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-navicon"></i>
                                                    fa-navicon
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-paper-plane"></i>
                                                    fa-paper-plane
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-paper-plane-o"></i>
                                                    fa-paper-plane-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-paw"></i>
                                                    fa-paw
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-pencil"></i>
                                                    fa-pencil
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-pencil-square"></i>
                                                    fa-pencil-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-pencil-square-o"></i>
                                                    fa-pencil-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-phone"></i>
                                                    fa-phone
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-phone-square"></i>
                                                    fa-phone-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-photo"></i>
                                                    fa-photo
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-picture-o"></i>
                                                    fa-picture-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-plane"></i>
                                                    fa-plane
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-plus"></i>
                                                    fa-plus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-plus-circle"></i>
                                                    fa-plus-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-plus-square"></i>
                                                    fa-plus-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-plus-square-o"></i>
                                                    fa-plus-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-power-off"></i>
                                                    fa-power-off
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-print"></i>
                                                    fa-print
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-puzzle-piece"></i>
                                                    fa-puzzle-piece
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-qrcode"></i>
                                                    fa-qrcode
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-question"></i>
                                                    fa-question
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-question-circle"></i>
                                                    fa-question-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-quote-left"></i>
                                                    fa-quote-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-quote-right"></i>
                                                    fa-quote-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-random"></i>
                                                    fa-random
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-recycle"></i>
                                                    fa-recycle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-refresh"></i>
                                                    fa-refresh
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-reorder"></i>
                                                    fa-reorder
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-reply"></i>
                                                    fa-reply
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-reply-all"></i>
                                                    fa-reply-all
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-retweet"></i>
                                                    fa-retweet
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-road"></i>
                                                    fa-road
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rocket"></i>
                                                    fa-rocket
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rss"></i>
                                                    fa-rss
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rss-square"></i>
                                                    fa-rss-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-search"></i>
                                                    fa-search
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-search-minus"></i>
                                                    fa-search-minus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-search-plus"></i>
                                                    fa-search-plus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-send"></i>
                                                    fa-send
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-send-o"></i>
                                                    fa-send-o
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-share"></i>
                                                    fa-share
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-share-alt"></i>
                                                    fa-share-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-share-alt-square"></i>
                                                    fa-share-alt-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-share-square"></i>
                                                    fa-share-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-share-square-o"></i>
                                                    fa-share-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-shield"></i>
                                                    fa-shield
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-shopping-cart"></i>
                                                    fa-shopping-cart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sign-in"></i>
                                                    fa-sign-in
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sign-out"></i>
                                                    fa-sign-out
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-signal"></i>
                                                    fa-signal
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sitemap"></i>
                                                    fa-sitemap
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sliders"></i>
                                                    fa-sliders
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-smile-o"></i>
                                                    fa-smile-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sort"></i>
                                                    fa-sort
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sort-alpha-asc"></i>
                                                    fa-sort-alpha-asc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sort-alpha-desc"></i>
                                                    fa-sort-alpha-desc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sort-amount-asc"></i>
                                                    fa-sort-amount-asc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sort-amount-desc"></i>
                                                    fa-sort-amount-desc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sort-asc"></i>
                                                    fa-sort-asc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sort-desc"></i>
                                                    fa-sort-desc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sort-down"></i>
                                                    fa-sort-down
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sort-numeric-asc"></i>
                                                    fa-sort-numeric-asc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-sort-numeric-desc"></i>
                                                    fa-sort-numeric-desc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sort-up"></i>
                                                    fa-sort-up
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-space-shuttle"></i>
                                                    fa-space-shuttle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spinner"></i>
                                                    fa-spinner
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spoon"></i>
                                                    fa-spoon
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-square"></i>
                                                    fa-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-square-o"></i>
                                                    fa-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-star"></i>
                                                    fa-star
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-star-half"></i>
                                                    fa-star-half
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-star-half-empty"></i>
                                                    fa-star-half-empty
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-star-half-full"></i>
                                                    fa-star-half-full
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-star-half-o"></i>
                                                    fa-star-half-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-star-o"></i>
                                                    fa-star-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-suitcase"></i>
                                                    fa-suitcase
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-sun-o"></i>
                                                    fa-sun-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-support"></i>
                                                    fa-support
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tablet"></i>
                                                    fa-tablet
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tachometer"></i>
                                                    fa-tachometer
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tag"></i>
                                                    fa-tag
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tags"></i>
                                                    fa-tags
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tasks"></i>
                                                    fa-tasks
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-taxi"></i>
                                                    fa-taxi
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-terminal"></i>
                                                    fa-terminal
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thumb-tack"></i>
                                                    fa-thumb-tack
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-thumbs-down"></i>
                                                    fa-thumbs-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-thumbs-o-down"></i>
                                                    fa-thumbs-o-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-thumbs-o-up"></i>
                                                    fa-thumbs-o-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-thumbs-up"></i>
                                                    fa-thumbs-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ticket"></i>
                                                    fa-ticket
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-times"></i>
                                                    fa-times
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-times-circle"></i>
                                                    fa-times-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-times-circle-o"></i>
                                                    fa-times-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tint"></i>
                                                    fa-tint
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-toggle-down"></i>
                                                    fa-toggle-down
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-toggle-left"></i>
                                                    fa-toggle-left
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-toggle-right"></i>
                                                    fa-toggle-right
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-toggle-up"></i>
                                                    fa-toggle-up
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-trash-o"></i>
                                                    fa-trash-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tree"></i>
                                                    fa-tree
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-trophy"></i>
                                                    fa-trophy
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-truck"></i>
                                                    fa-truck
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-umbrella"></i>
                                                    fa-umbrella
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-university"></i>
                                                    fa-university
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-unlock"></i>
                                                    fa-unlock
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-unlock-alt"></i>
                                                    fa-unlock-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-unsorted"></i>
                                                    fa-unsorted
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-upload"></i>
                                                    fa-upload
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user"></i>
                                                    fa-user
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-users"></i>
                                                    fa-users
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-video-camera"></i>
                                                    fa-video-camera
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-volume-down"></i>
                                                    fa-volume-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-volume-off"></i>
                                                    fa-volume-off
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-volume-up"></i>
                                                    fa-volume-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-warning"></i>
                                                    fa-warning
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wheelchair"></i>
                                                    fa-wheelchair
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wrench"></i>
                                                    fa-wrench
                                                </div>

                                            </div>

                                        </section>


                                        <section id="file-type">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>File Type Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file"></i>
                                                    fa-file
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-archive-o"></i>
                                                    fa-file-archive-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-audio-o"></i>
                                                    fa-file-audio-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-code-o"></i>
                                                    fa-file-code-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-excel-o"></i>
                                                    fa-file-excel-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-image-o"></i>
                                                    fa-file-image-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-movie-o"></i>
                                                    fa-file-movie-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-o"></i>
                                                    fa-file-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-pdf-o"></i>
                                                    fa-file-pdf-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-photo-o"></i>
                                                    fa-file-photo-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-picture-o"></i>
                                                    fa-file-picture-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-powerpoint-o"></i>
                                                    fa-file-powerpoint-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-sound-o"></i>
                                                    fa-file-sound-o
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-text"></i>
                                                    fa-file-text
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-text-o"></i>
                                                    fa-file-text-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-video-o"></i>
                                                    fa-file-video-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-word-o"></i>
                                                    fa-file-word-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-zip-o"></i>
                                                    fa-file-zip-o
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                            </div>

                                        </section>

                                        <section id="spinner">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Spinner Icons</b>
                                            </h4>

                                            <div className="alert alert-success">
                                                These icons work great with the
                                                <code>fa-spin</code>
                                                className. Check out
                                                the
                                                <a href="" className="alert-link">spinning icons example</a>.
                                            </div>

                                            <div className="row icon-demo-content">
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-spin fa-circle-o-notch" style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        lineHeight: "1px",
                                                        marginRight: "10px"
                                                    }}></i>
                                                    fa-circle-o-notch
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spin fa-cog"
                                                       style={{
                                                           width: "auto",
                                                           height: "auto",
                                                           lineHeight: "1px",
                                                           marginRight: "10px"
                                                       }}></i>
                                                    fa-cog
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spin fa-gear" style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        lineHeight: "1px",
                                                        marginRight: "10px"
                                                    }}></i>
                                                    fa-gear
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spin fa-refresh" style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        lineHeight: "1px",
                                                        marginRight: "10px"
                                                    }}></i>
                                                    fa-refresh
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spin fa-spinner" style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        lineHeight: "1px",
                                                        marginRight: "10px"
                                                    }}></i>
                                                    fa-spinner
                                                </div>

                                            </div>
                                        </section>

                                        <section id="form-control">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Form Control Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">


                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-check-square"></i>
                                                    fa-check-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-check-square-o"></i>
                                                    fa-check-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-circle"></i>
                                                    fa-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-circle-o"></i>
                                                    fa-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-dot-circle-o"></i>
                                                    fa-dot-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-minus-square"></i>
                                                    fa-minus-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-minus-square-o"></i>
                                                    fa-minus-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-plus-square"></i>
                                                    fa-plus-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-plus-square-o"></i>
                                                    fa-plus-square-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-square"></i>
                                                    fa-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-square-o"></i>
                                                    fa-square-o
                                                </div>

                                            </div>
                                        </section>

                                        <section id="currency">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Currency Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">


                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bitcoin"></i>
                                                    fa-bitcoin
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-btc"></i>
                                                    fa-btc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cny"></i>
                                                    fa-cny
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-dollar"></i>
                                                    fa-dollar
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eur"></i>
                                                    fa-eur
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-euro"></i>
                                                    fa-euro
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gbp"></i>
                                                    fa-gbp
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-inr"></i>
                                                    fa-inr
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-jpy"></i>
                                                    fa-jpy
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-krw"></i>
                                                    fa-krw
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-money"></i>
                                                    fa-money
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rmb"></i>
                                                    fa-rmb
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rouble"></i>
                                                    fa-rouble
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rub"></i>
                                                    fa-rub
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ruble"></i>
                                                    fa-ruble
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rupee"></i>
                                                    fa-rupee
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-try"></i>
                                                    fa-try
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-turkish-lira"></i>
                                                    fa-turkish-lira
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-usd"></i>
                                                    fa-usd
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-won"></i>
                                                    fa-won
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-yen"></i>
                                                    fa-yen
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                            </div>

                                        </section>

                                        <section id="text-editor">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Text Editor Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">


                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-align-center"></i>
                                                    fa-align-center
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-align-justify"></i>
                                                    fa-align-justify
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-align-left"></i>
                                                    fa-align-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-align-right"></i>
                                                    fa-align-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bold"></i>
                                                    fa-bold
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-chain"></i>
                                                    fa-chain
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chain-broken"></i>
                                                    fa-chain-broken
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-clipboard"></i>
                                                    fa-clipboard
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-columns"></i>
                                                    fa-columns
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-copy"></i>
                                                    fa-copy
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-cut"></i>
                                                    fa-cut
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-dedent"></i>
                                                    fa-dedent
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eraser"></i>
                                                    fa-eraser
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file"></i>
                                                    fa-file
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-o"></i>
                                                    fa-file-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-file-text"></i>
                                                    fa-file-text
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-file-text-o"></i>
                                                    fa-file-text-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-files-o"></i>
                                                    fa-files-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-floppy-o"></i>
                                                    fa-floppy-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-font"></i>
                                                    fa-font
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-header"></i>
                                                    fa-header
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-indent"></i>
                                                    fa-indent
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-italic"></i>
                                                    fa-italic
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-link"></i>
                                                    fa-link
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-list"></i>
                                                    fa-list
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-list-alt"></i>
                                                    fa-list-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-list-ol"></i>
                                                    fa-list-ol
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-list-ul"></i>
                                                    fa-list-ul
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-outdent"></i>
                                                    fa-outdent
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-paperclip"></i>
                                                    fa-paperclip
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-paragraph"></i>
                                                    fa-paragraph
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-paste"></i>
                                                    fa-paste
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-repeat"></i>
                                                    fa-repeat
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-rotate-left"></i>
                                                    fa-rotate-left
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-rotate-right"></i>
                                                    fa-rotate-right
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-save"></i>
                                                    fa-save
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-scissors"></i>
                                                    fa-scissors
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-strikethrough"></i>
                                                    fa-strikethrough
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-subscript"></i>
                                                    fa-subscript
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-superscript"></i>
                                                    fa-superscript
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-table"></i>
                                                    fa-table
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-text-height"></i>
                                                    fa-text-height
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-text-width"></i>
                                                    fa-text-width
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-th"></i>
                                                    fa-th
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-th-large"></i>
                                                    fa-th-large
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-th-list"></i>
                                                    fa-th-list
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-underline"></i>
                                                    fa-underline
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-undo"></i>
                                                    fa-undo
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-unlink"></i>
                                                    fa-unlink
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                            </div>

                                        </section>


                                        <section id="directional">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Directional Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">


                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-angle-double-down"></i>
                                                    fa-angle-double-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-angle-double-left"></i>
                                                    fa-angle-double-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-angle-double-right"></i>
                                                    fa-angle-double-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-angle-double-up"></i>
                                                    fa-angle-double-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-angle-down"></i>
                                                    fa-angle-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-angle-left"></i>
                                                    fa-angle-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-angle-right"></i>
                                                    fa-angle-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-angle-up"></i>
                                                    fa-angle-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-down"></i>
                                                    fa-arrow-circle-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-left"></i>
                                                    fa-arrow-circle-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-o-down"></i>
                                                    fa-arrow-circle-o-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-o-left"></i>
                                                    fa-arrow-circle-o-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-o-right"></i>
                                                    fa-arrow-circle-o-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-o-up"></i>
                                                    fa-arrow-circle-o-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-right"></i>
                                                    fa-arrow-circle-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-circle-up"></i>
                                                    fa-arrow-circle-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrow-down"></i>
                                                    fa-arrow-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrow-left"></i>
                                                    fa-arrow-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-arrow-right"></i>
                                                    fa-arrow-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrow-up"></i>
                                                    fa-arrow-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows"></i>
                                                    fa-arrows
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows-alt"></i>
                                                    fa-arrows-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows-h"></i>
                                                    fa-arrows-h
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows-v"></i>
                                                    fa-arrows-v
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-caret-down"></i>
                                                    fa-caret-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-caret-left"></i>
                                                    fa-caret-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-right"></i>
                                                    fa-caret-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-caret-up"></i>
                                                    fa-caret-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-left"></i>
                                                    fa-caret-square-o-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-right"></i>
                                                    fa-caret-square-o-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-up"></i>
                                                    fa-caret-square-o-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-caret-square-o-down"></i>
                                                    fa-caret-square-o-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-circle-down"></i>
                                                    fa-chevron-circle-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-circle-left"></i>
                                                    fa-chevron-circle-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-circle-right"></i>
                                                    fa-chevron-circle-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-circle-up"></i>
                                                    fa-chevron-circle-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-down"></i>
                                                    fa-chevron-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-left"></i>
                                                    fa-chevron-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-chevron-right"></i>
                                                    fa-chevron-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-chevron-up"></i>
                                                    fa-chevron-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-o-down"></i>
                                                    fa-hand-o-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-o-left"></i>
                                                    fa-hand-o-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hand-o-right"></i>
                                                    fa-hand-o-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-hand-o-up"></i>
                                                    fa-hand-o-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-long-arrow-down"></i>
                                                    fa-long-arrow-down
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-long-arrow-left"></i>
                                                    fa-long-arrow-left
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-long-arrow-right"></i>
                                                    fa-long-arrow-right
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-long-arrow-up"></i>
                                                    fa-long-arrow-up
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-toggle-down"></i>
                                                    fa-toggle-down
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-toggle-left"></i>
                                                    fa-toggle-left
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-toggle-right"></i>
                                                    fa-toggle-right
                                                    <span
                                                        className="text-muted">(alias)
                                                    </span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-toggle-up"></i>
                                                    fa-toggle-up
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                            </div>

                                        </section>

                                        <section id="video-player">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Video Player Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">


                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-arrows-alt"></i>
                                                    fa-arrows-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-backward"></i>
                                                    fa-backward
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-compress"></i>
                                                    fa-compress
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-eject"></i>
                                                    fa-eject
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-expand"></i>
                                                    fa-expand
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-fast-backward"></i>
                                                    fa-fast-backward
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-fast-forward"></i>
                                                    fa-fast-forward
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-forward"></i>
                                                    fa-forward
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-pause"></i>
                                                    fa-pause
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-play"></i>
                                                    fa-play
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-play-circle"></i>
                                                    fa-play-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-play-circle-o"></i>
                                                    fa-play-circle-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-step-backward"></i>
                                                    fa-step-backward
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-step-forward"></i>
                                                    fa-step-forward
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-stop"></i>
                                                    fa-stop
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-youtube-play"></i>
                                                    fa-youtube-play
                                                </div>

                                            </div>

                                        </section>

                                        <section id="brand">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Brand Icons</b>
                                            </h4>

                                            <div className="alert alert-success">
                                                <ul className="margin-bottom-none padding-left-lg">
                                                    <li>All brand icons are trademarks of their respective owners.</li>
                                                    <li>The use of these trademarks does not indicate endorsement of the
                                                        trademark holder by Font Awesome, nor vice versa.
                                                    </li>
                                                </ul>

                                            </div>
                                            <div className="row icon-demo-content">

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-adn"></i>
                                                    fa-adn
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-android"></i>
                                                    fa-android
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-apple"></i>
                                                    fa-apple
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-behance"></i>
                                                    fa-behance
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-behance-square"></i>
                                                    fa-behance-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bitbucket"></i>
                                                    fa-bitbucket
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-bitbucket-square"></i>
                                                    fa-bitbucket-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-bitcoin"></i>
                                                    fa-bitcoin
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-btc"></i>
                                                    fa-btc
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-codepen"></i>
                                                    fa-codepen
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-css3"></i>
                                                    fa-css3
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-delicious"></i>
                                                    fa-delicious
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-deviantart"></i>
                                                    fa-deviantart
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-digg"></i>
                                                    fa-digg
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-dribbble"></i>
                                                    fa-dribbble
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-dropbox"></i>
                                                    fa-dropbox
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-drupal"></i>
                                                    fa-drupal
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-empire"></i>
                                                    fa-empire
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-facebook"></i>
                                                    fa-facebook
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-facebook-square"></i>
                                                    fa-facebook-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-flickr"></i>
                                                    fa-flickr
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-foursquare"></i>
                                                    fa-foursquare
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ge"></i>
                                                    fa-ge
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-git"></i>
                                                    fa-git
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-git-square"></i>
                                                    fa-git-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-github"></i>
                                                    fa-github
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-github-alt"></i>
                                                    fa-github-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-github-square"></i>
                                                    fa-github-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-gittip"></i>
                                                    fa-gittip
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-google"></i>
                                                    fa-google
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-google-plus"></i>
                                                    fa-google-plus
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-google-plus-square"></i>
                                                    fa-google-plus-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-hacker-news"></i>
                                                    fa-hacker-news
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-html5"></i>
                                                    fa-html5
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-instagram"></i>
                                                    fa-instagram
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-joomla"></i>
                                                    fa-joomla
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-jsfiddle"></i>
                                                    fa-jsfiddle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-linkedin"></i>
                                                    fa-linkedin
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-linkedin-square"></i>
                                                    fa-linkedin-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-linux"></i>
                                                    fa-linux
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-maxcdn"></i>
                                                    fa-maxcdn
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-openid"></i>
                                                    fa-openid
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-pagelines"></i>
                                                    fa-pagelines
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-pied-piper"></i>
                                                    fa-pied-piper
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-pied-piper-alt"></i>
                                                    fa-pied-piper-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-pinterest"></i>
                                                    fa-pinterest
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-pinterest-square"></i>
                                                    fa-pinterest-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-qq"></i>
                                                    fa-qq
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ra"></i>
                                                    fa-ra
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-rebel"></i>
                                                    fa-rebel
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-reddit"></i>
                                                    fa-reddit
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-reddit-square"></i>
                                                    fa-reddit-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-renren"></i>
                                                    fa-renren
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-share-alt"></i>
                                                    fa-share-alt
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-share-alt-square"></i>
                                                    fa-share-alt-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-skype"></i>
                                                    fa-skype
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-slack"></i>
                                                    fa-slack
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-soundcloud"></i>
                                                    fa-soundcloud
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-spotify"></i>
                                                    fa-spotify
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-stack-exchange"></i>
                                                    fa-stack-exchange
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-stack-overflow"></i>
                                                    fa-stack-overflow
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-steam"></i>
                                                    fa-steam
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-steam-square"></i>
                                                    fa-steam-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-stumbleupon"></i>
                                                    fa-stumbleupon
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-stumbleupon-circle"></i>
                                                    fa-stumbleupon-circle
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-tencent-weibo"></i>
                                                    fa-tencent-weibo
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-trello"></i>
                                                    fa-trello
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-tumblr"></i>
                                                    fa-tumblr
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-tumblr-square"></i>
                                                    fa-tumblr-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-twitter"></i>
                                                    fa-twitter
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-twitter-square"></i>
                                                    fa-twitter-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-vimeo-square"></i>
                                                    fa-vimeo-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-vine"></i>
                                                    fa-vine
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-vk"></i>
                                                    fa-vk
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wechat"></i>
                                                    fa-wechat
                                                    <span className="text-muted">(alias)</span>
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-weibo"></i>
                                                    fa-weibo
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-weixin"></i>
                                                    fa-weixin
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-windows"></i>
                                                    fa-windows
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wordpress"></i>
                                                    fa-wordpress
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-xing"></i>
                                                    fa-xing
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-xing-square"></i>
                                                    fa-xing-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-yahoo"></i>
                                                    fa-yahoo
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-youtube"></i>
                                                    fa-youtube
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-youtube-play"></i>
                                                    fa-youtube-play
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-youtube-square"></i>
                                                    fa-youtube-square
                                                </div>

                                            </div>
                                        </section>

                                        <section id="medical">
                                            <h4 className="page-header header-title m-b-30">
                                                <b>Medical Icons</b>
                                            </h4>

                                            <div className="row icon-demo-content">
                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-ambulance"></i>
                                                    fa-ambulance
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-h-square"></i>
                                                    fa-h-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-hospital-o"></i>
                                                    fa-hospital-o
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-medkit"></i>
                                                    fa-medkit
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-plus-square"></i>
                                                    fa-plus-square
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i
                                                        className="fa fa-stethoscope"></i>
                                                    fa-stethoscope
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-user-md"></i>
                                                    fa-user-md
                                                </div>

                                                <div className="col-xl-3 col-md-4 col-sm-6">
                                                    <i className="fa fa-wheelchair"></i>
                                                    fa-wheelchair
                                                </div>

                                            </div>

                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}

export default Icons_fontawesome;   