import React from "react";
import OrderTable from "../components/OrderTable/OrderTable";
import PageTemplate from "../layout/PageTemplate";
import { getWalmartOrder } from '../fetch/walmart_orders';
import { getMatchedOrders} from "../fetch/amazon_orders";
class Home extends React.Component {
    render() {
        return (
            <PageTemplate account={this.props.match.params.nameAccount} title='Amazon orders vs Waltmart orders'>
                <OrderTable/>
            </PageTemplate>
        );
    }
}

export default Home;
