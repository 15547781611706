const dev = {
    /*api : 'http://localhost:8080/api/v1',
    apiPython: 'http://localhost:5000/api/v1'
    */
    api: 'https://tobim-ms.ue.r.appspot.com/api/v1',
    apiPython: 'https://secondary-dot-tobim-ms.ue.r.appspot.com/api/v1'
    
};

const prod = {
    /*
    api : 'http://localhost:8080/api/v1',
    apiPython: 'http://localhost:5000/api/v1'
    */
    api: 'https://tobim-ms.ue.r.appspot.com/api/v1', 
    apiPython: 'https://secondary-dot-tobim-ms.ue.r.appspot.com/api/v1'
};


const config = process.env.REACT_APP_ENV === 'prod' ? prod : dev;

export default {
    ...config
};


/*
export const api = 'http://127.0.0.1:8080/api/v1'
export const api = 'https://prod-dot-tobim-ms.ue.r.appspot.com/api/v1'
export const amazonOrdersApi = api + '/amazon_orders'
export  const accountsApi = api + '/accounts'
export const waltmartEmaiApi =  api + '/walmart/email'
*/

