import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function FormAmazonSetting({
  accesskey,
  setaccesskey,
  secret,
  setsecret,
  sellerId,
  setseller,
  market,
  setmarket,
  app,
  setapp,
  version,
  setversion,
  token,
  settoken,
}) {
  return (
    <section className="col-6">
      <header className="d-flex">
        <h2>Amazon</h2>
        <div>
          <FontAwesomeIcon icon={faAmazon} size={"2x"} />
        </div>
      </header>
      <div className="d-flex">
        <div className="input-section">
          <label htmlFor="accessKey" className="d-block">
            accessKey
          </label>
          <input
            type="text"
            id="accessKey"
            className="form-control input"
            value={accesskey}
            onChange={(e) => {
              setaccesskey(e.target.value);
            }}
          />
        </div>

        <div className="input-section ml-4">
          <label htmlFor="secret" className="d-block">
            secret
          </label>
          <input
            type="password"
            id="secret"
            className="form-control input"
            value={secret}
            onChange={(e) => {
              setsecret(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="input-section">
          <label htmlFor="sellerId" className="d-block">
            sellerId
          </label>
          <input
            type="text"
            id="sellerId"
            className="form-control input"
            value={sellerId}
            onChange={(e) => {
              setseller(e.target.value);
            }}
          />
        </div>

        <div className="input-section ml-4">
          <label htmlFor="market" className="d-block">
            market
          </label>
          <input
            type="text"
            id="market"
            className="form-control input"
            value={market}
            onChange={(e) => {
              setmarket(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="d-flex">
        <div className="input-section">
          <label htmlFor="appName" className="d-block">
            appName
          </label>
          <input
            type="text"
            id="appName"
            className="form-control input"
            value={app}
            onChange={(e) => {
              setapp(e.target.value);
            }}
          />
        </div>

        <div className="input-section ml-4">
          <label htmlFor="version" className="d-block t">
            version
          </label>
          <input
            type="text"
            id="version"
            className="form-control input"
            value={version}
            onChange={(e) => {
              setversion(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="input-section">
        <label htmlFor="token" className="d-block t">
          Token
        </label>
        <input
          type="text"
          id="token"
          className="form-control input"
          value={token}
          onChange={(e) => {
            settoken(e.target.value);
          }}
        />
      </div>
    </section>
  );
}
