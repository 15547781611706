import { Collapse } from "@material-ui/core";

import React, { useState, useEffect } from "react";
import { currencyFormat } from "../../../../../utils/currency";
import IconCollapse from "../../IconCollapse/IconCollapse";
import TableCollapse from "../../TableCollapse/TableCollapse";

export default function WalmartDetail({ row }) {
  const [openWalmart, setOpenWalmart] = useState(true);

  const walmart = row.walmart_order || row; 
  
  const principal =  walmart?.summary?.subtotal;
  const shipping = walmart?.summary?.shipping;
  const total = walmart?.summary?.total;
  const totalAmazon = row?.financialSummary?.itemPrice?  row.financialSummary.itemPrice: 0 
  const totalWalmart = total  /*row?.walmartOrder?.summary?.total : 0*/
  
 

  return (
    <div className="bg-white text-capitalize">
      <table className="table m-0 table-sm table-condensed table-borderless text-right text-capitalize">
        <colgroup>
          <col style={{ width: "70%" }} />
          <col style={{ width: "20%" }} />
        </colgroup>
        <tbody>
          <tr className="font-weight-bold">
            <td>
              <IconCollapse
                onOpen={() => setOpenWalmart(!openWalmart)}
                open={openWalmart}
              />
              <span>Walmart</span>
            </td>
            <td>
              <span className="ml-auto">{currencyFormat(total)}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <Collapse in={openWalmart}>
        <table className="table m-0 table-sm table-condensed table-borderless text-right text-capitalize">
          <colgroup>
            <col style={{ width: "70%" }} />
            <col style={{ width: "20%" }} />
          </colgroup>
          <tbody />

          <TableCollapse name="charges" total={total} startOpen={true}>
            <tr>
              <td className="pl-5 font-14">Principal</td>
              <td className="text-right">{currencyFormat(principal)}</td>
            </tr>
            <tr>
              <td className="pl-5 font-14">Shipping</td>
              <td className="text-right">{currencyFormat(shipping)}</td>
            </tr>
          </TableCollapse>
          <tbody>
            <tr>
              <td className="font-weight-bold pl-4 text-right">subtotal</td>
              <td className="text-right">{currencyFormat(total)}</td>
            </tr>
            {totalAmazon!=0 &&
            (<tr>
              
              <td className="font-weight-bold pl-4 text-right">Net Profit</td>
              
              <td className="text-right">
              {currencyFormat(totalAmazon-totalWalmart)}
                </td>
            </tr>
            )}
          </tbody>
        </table>
      </Collapse>
    </div>
  );
}
