import PageTemplate from "../layout/PageTemplate";
import React, {useEffect, useState } from "react";
import { searchUnmatchedOrders } from "../fetch/walmart_orders";
import { getUnmatchedWalmartOrders } from "../fetch/amazon_orders";
import { withRouter } from "react-router-dom";
import Pagination from "../components/amazon/pagination/Pagination";
import { currencyFormat } from "../../../utils/currency";
import {exportReport} from "../fetch/walmart_orders";
import OrderTable from "../components/OrderTable/OrderTable";
import UnmatchedOrderTable from "../components/UnmatchedOrderTable/UnmatchedOrderTable";
const Unmatched = (props) => {
  const [orders, setOrders] = useState([]);
  const [size, setSize] = useState(20); 
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(1); 

  useEffect(()=>{
    getOrders(page, size); 
  }, [])

  const getOrders = async (page, size) =>{
    const response = await getUnmatchedWalmartOrders(
      props.match.params.account,
      page,
      size
    );

    if (response.status === 200) {
      const data = await response.json();
      setOrders(data);
      setTotalPages(data[0].totalpages);
      setTotalElements(data[0].totalElements);
      console.log(orders);
    }
  }

  const handleNext = () => {
    setPage(page+1)
    getOrders(page+1, size)
    
  };

  const handlePrev = () => {
    setPage(page-1)
    getOrders(page-1, size)
  
  };

  const handleSelect = (selected) => {
    setPage(selected);
    getOrders(selected, size);
  };

  const handleExport = () => {
    exportReport('hello.txt','text/plain', props.match.params.account , props.match.params.nameAccount).then((blob)=> downloadReport(blob)); 
  }

  const downloadReport = (blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${props.match.params.nameAccount}-report ${new Date().toLocaleString()}.csv`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
  }

  const handlerChange = async (event) => {
    // tomar el valor a buscar
    const value = event.target ? event.target.value : event;

    // ir a el backend y recibir los resultados
    const response = await  searchUnmatchedOrders(value);
    const json = await response.json();
    /*if (json.length === 0 && !value) {
      this.setState({show: this.state.orders})
      return;
    }
    // // actualizar el listado de resultado
    this.setState({show: json})*/
    setOrders(json);
    setTotalPages(json[0].totalpages);
    setTotalElements(json[0].totalElements);
  };

  
    return (
      <PageTemplate title="Unmatched Walmart Orders" account={props.match.params.nameAccount}>
        <UnmatchedOrderTable orders = {orders} totalPages={totalPages} totalElements={totalElements} page={page} size={size} handleNext={handleNext} handlePrev={handlePrev} handleSelect={handleSelect} handleExport={handleExport} handlerChange={handlerChange}/>
      </PageTemplate>
    );
}


export default withRouter(Unmatched);

