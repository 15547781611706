import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, InputAdornment, TextField } from "@material-ui/core";
import React from "react";

export default function OrderBar({
  page,
  size,
  onPage,
  onSize,
  onChange,
  onExport,
}) {
  return (
      <div className="row justify-content-end">
        <div className="col-2">
          <TextField
            id="date"
            type="date"
          />
        </div>
        <div className="col-2">
          <TextField
            id="date"
            type="date"
          />
        </div>
    
      
      <div className="col-3">
        <TextField
          id="outlined-basic"
          //label="type and press enter"
          className="w-responsive"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
          onKeyUp={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onChange(e.target.value);
            }
          }}
        />
        <button className="btn btn-success ml-2" onClick={()=>onExport()}>Export</button>
        </div>
        </div>
  );
}
