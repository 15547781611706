import { withRouter } from "react-router-dom";
import { getAmazonOrders, searchOrders , exportReportMatched, getMatchedOrders} from "../../fetch/amazon_orders";
import Pagination from "../amazon/pagination/Pagination";
import OrderBar from "../OrderReport/OrderBar";
import { HomeReportRow } from "./OrderTableRow";
import React, { Component } from "react";
import { getWalmartOrder } from "../../fetch/walmart_orders";
class OrderTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      show: [],
      page: 1,
      size: 100,
      totalpages: 0,
      totalElements: 0,
      account: null,
    };
  }

  async componentDidMount() {  
    await this.getOrders(this.state.page, this.state.size);
  }

  getOrders = async (page, size) => {
    const response = await getMatchedOrders(this.props.match.params.account, page, size);
    const json = await response.json();
    this.setState({
      orders: json,
      totalpages: json[0]?.totalpages,
      totalElements: json[0]?.totalElements
    });

    console.log(json);
    console.log(this.state.orders); 
  };

  handlerChange = async (event) => {
    // tomar el valor a buscar
    const value = event.target ? event.target.value : event;

    // ir a el backend y recibir los resultados
    const response = await searchOrders(value);
    const json = await response.json();
    /*if (json.length === 0 && !value) {
      this.setState({show: this.state.orders})
      return;
    }
    // // actualizar el listado de resultado
    this.setState({show: json})*/
    this.setState({
      orders: json,
      totalpages: json[0]?.totalpages,
      totalElements: json[0]?.totalElements
    });
  };

  handleNext = () => {
    this.setState({page: this.state.page+1})
    this.getOrders(this.state.page+1, this.state.size);
  };

  handlePrev = () => {
    this.setState({page: this.state.page-1})
    this.getOrders(this.state.page-1, this.state.size);
  
  };

  handleSelect = (selected) => {
    this.setState({page: selected})
    this.getOrders(selected, this.state.size);
  };



  handleExport = () => {
    exportReportMatched('hello.txt','text/plain', this.props.match.params.account , this.props.match.params.nameAccount).then((blob)=> this.downloadReport(blob)); 
  }

  downloadReport = (blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${this.props.match.params.nameAccount}-report ${new Date().toLocaleString()}.csv`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    }

  render() {
    return (
      <div className="mb-5 text-capitalize">
        {this.orders && this.orders?.length === 0 && (
          <div className="text-center">Not orders matching</div>
        )}
        {this.state.orders && this.state.orders.length > 0 && (
          <React.Fragment>
            <div>
                <OrderBar
                  page={this.state.page}
                  size={this.state.size}
                  onPage={(e) => this.setState({ page: e })}
                  onSize={(e) => this.setState({ size: e })}
                  onChange={this.handlerChange}
                  onExport={this.handleExport}
                />
            </div>
            <div className="d-flex">
              <ul className="d-flex list-unstyled navigation-menu align-baseline align-items-end">
                <li className="d-flex" style={{ marginLeft: "12em" }}>
                  Order Id
                </li>
                <li style={{ marginLeft: "6.5em" }}>Purchase Date</li>
              </ul>
            </div>
            {this.state.orders.map((row) => (
              <HomeReportRow row={row} key={row['_id']} />
            ))}

            <Pagination
              totalElements={this.state.totalElements}
              totalPages={this.state.totalpages}
              page={this.state.page}
              onNext={this.handleNext}
              onPrev={this.handlePrev}
              onSelect={this.handleSelect}
            />
          </React.Fragment>
        )}
        
      </div>
    );
  }
}

export default withRouter(OrderTable);
