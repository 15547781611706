import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { actionTypes } from "redux-form";
import { fireApp } from "../../..";
import Aux from "../../template/hoc/Aux_";


function Login(props) {
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);

  const onLogin = async() => {

    if(!email){
        return
    }
    if(!password){
        return
    }
    await fireApp.auth().signInWithEmailAndPassword(email, password)
  };

  return (
    <Aux>
      <div className=""></div>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-body">
            <h2 className="text-center m-0">
              {/* <img src="assets/images/logo.png" height="30" alt="logo"/> */}
              Tobim
            </h2>
            <div className="p-3">
              <h4 className="font-18 m-b-5 text-center">Welcome Back !</h4>
              <p className="text-muted text-center">
                Sign in to continue to Tobim.
              </p>
              <form className="form-horizontal m-t-30" onSubmit={e=>e.preventDefault()} >
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder="Enter username"
                    onChange={(e)=>setemail(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="userpassword">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="userpassword"
                    placeholder="Enter password"
                    onChange={(e)=>setpassword(e.target.value)}
                  />
                </div>
                <div className="form-group row m-t-20">
                  <div className="col-sm-6 text-right">
                    <button
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                      onClick={()=>onLogin()}
                    >
                      Log In
                    </button>
                  </div>
                </div>
                <div className="form-group m-t-10 mb-0 row">
                  <div className="col-12 m-t-20">
                    <Link to="Pages_recoverpw" className="text-muted">
                      <i className="mdi mdi-lock"></i>
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="m-t-40 text-center">
          <p className="text-white">
            Don't have an account ?
            <Link
              to="pages_register"
              className="font-500 font-14 text-white font-secondary"
            >
              Signup Now
            </Link>
          </p>
        </div>
      </div>
    </Aux>
  );
}



const mapStatetoProps = state => {
    return {
        login: state.ui_red.loginlogin
    };
}

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({type: "login", value: true}),
        UpdateLoginAgain: () => dispatch({type: "login", value: false})
    };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Login);