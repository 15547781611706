export const currencyFormat = (number) => {

  if (!number)
    number=0;

  return '$ ' + new Intl.NumberFormat("en-IN", {
    // style: "currency",
    // currency: "USD",
    minimumFractionDigits:2
    // maximumSignificantDigits: 3,
  }).format(number);
};
