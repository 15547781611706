import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Collapse} from "@material-ui/core";
import React, { useEffect } from "react";
import '../OrderTable/OrderTableRow.sass';
import {faAmazon} from "@fortawesome/free-brands-svg-icons";
import {formatDate, formatTimeStamp} from "../../../../utils/date/formatDates";
import IconCollapse from "../IconCollapse/IconCollapse";
import IconWalmart from "../walmart/IconWalmart/IconWalmart";
import AmazonDetail from "../amazon/AmazonDetail/AmazonDetail";
import WalmartDetail from "../walmart/WalmartDetail/WalmartDetail";
import { currencyFormat } from "../../../../utils/currency";

export const UnmatchedAmazonOrderTableRow = (props) => {
    const {row} = props;
    const [open, setOpen] = React.useState(false);
    const iconSize = "1em";
    const iconSizeMd = "2em";
    const totalAmazon = row?.financialSummary?.itemPrice + row?.financialSummary?.shippingPrice    ||  0

    return (
        <div className="order-row__container text-capitalize">
            <ul className="d-flex">
                <li className="order-row__down-item">
                    <IconCollapse open={open} onOpen={() => setOpen(!open)}/>
                </li>
                <li className="mr-3">
                <FontAwesomeIcon icon={faAmazon} size={"2x"}/>
                </li>
                <li className="font-14">{row['_id']}</li>
                <li className="order-row__date">{row.purchaseLocalDate}</li>
                <li className="ml-auto">
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                <FontAwesomeIcon icon={faAmazon} size={"1x"}/>
                                </td>
                                <td className='text-right'>
                                    <span className="ml-1 font-16 font-weight-lighter">
                                    {currencyFormat(totalAmazon)}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <span className="d-block font-weight-lighter"/>
                </li>
                <li/>
                <li className='text-right'>
                    <span className="text-success font-20 font-weight-bold">
                    {currencyFormat(totalAmazon)}
                        </span>
                </li>
            </ul>
            <Collapse className="pl-3 " in={open} timeout="auto">
                <div className="row col-12 bg-white my-3 p-0 ml-auto mr-3">
                    <div className="col-8 px-4 py-3 pl-5">
                        <div className="mb-3">
                            <p>
                                <span className="d-block font-weight-bold font-20">
                                <FontAwesomeIcon className="mr-3" icon={faAmazon} size="1x"/>
                                    Amazon
                                </span>
                            </p>
                            <table>
                                <tbody>
                                <tr>
                                        <td>Order Id:</td>
                                        <td>{row['_id']}</td>
                                    </tr>
                                    <tr>
                                        <td>Date:</td>
                                        <td>{row.purchaseLocalDate}</td>
                                    </tr>
                                    <tr>
                                        <td>Customer:</td>
                                        <td>{row?.buyerName}</td>
                                    </tr>
                                    <tr>
                                        <td>Country:</td>
                                        <td>{row?.shippingAddress?.country}</td>
                                    </tr>
                                    <tr>
                                        <td>State:</td>
                                        <td>{row?.shippingAddress?.stateOrRegion}</td>
                                    </tr>
                                    <tr>
                                        <td>City:</td>
                                        <td>{row?.shippingAddress?.city}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col">
                    <table className="table mt-4 table-sm table-condensed table-borderless text-right text-capitalize">
                    <colgroup>
                        <col style={{width: "70%"}}/>
                        <col style={{width: "20%"}}/>
                    </colgroup>
                    <tbody>
                        <tr>
                        <td>
                            Item Price:
                        </td>
                        
                        <td>
                            {currencyFormat(row?.financialSummary?.itemPrice)}
                        </td>
                        </tr>
                        <tr>
                        <td>
                            Item Tax:
                        </td>
                        <td>
                            {currencyFormat(row?.financialSummary?.itemTax)}
                        </td>
                        </tr>
                        <tr>
                        <td>
                            Shipping Price:
                        </td>
                        <td>
                            {currencyFormat(row?.financialSummary?.shippingPrice)}
                        </td>
                        </tr>
                        <tr>
                        <td>
                            Shipping Tax:
                        </td>
                        <td>
                            {currencyFormat(row?.financialSummary?.shippingTax)}
                        </td>
                        </tr>
                    </tbody>
        </table>
                    </div>
                </div>
            </Collapse>
        </div>
    );
};
