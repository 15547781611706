import {IconButton} from '@material-ui/core'
import React from 'react'
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

export default function IconCollapse({onOpen, open}) {
    return (
        <React.Fragment>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => onOpen()}
            >
                {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
            </IconButton>

        </React.Fragment>
    )
}
