import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {Scrollbars} from "react-custom-scrollbars";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMailchimp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { LOGIN, USER } from "../../store/action";
import { fireApp } from "../../../..";

class topbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Tab: "index",
            SubTab: "",
            MoreTab: "",
            dashboard_menu: false,
            ui_menu: false,
            comp_menu: false,
            page_menu: false,
            eco_menu: false,
            dropdownOpen: false,
            dropdownOpen1: false,
            dropdownOpenprofile: false,
            dropdownOpenbadge: false,
            toggleEmail: false,
            toggleForm: false,
            toggleChart: false,
            toggleTable: false,
            toggleIcon: false,
            toggleMap: false,
            toggleEmailTemp: false,
        };

        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggleprofile = this.toggleprofile.bind(this);
        this.togglebadge = this.togglebadge.bind(this);
    }

    toggle() {
        this.setState((prevState) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));
    }

    toggle1() {
        this.setState((prevState) => ({
            dropdownOpen1: !prevState.dropdownOpen1,
        }));
    }

    toggleprofile() {
        this.setState((prevState) => ({
            dropdownOpenprofile: !prevState.dropdownOpenprofile,
        }));
    }

    togglebadge() {
        this.setState((prevState) => ({
            dropdownOpenbadge: !prevState.dropdownOpenbadge,
        }));
    }

    setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
        this.setState({Tab: tab, SubTab: subtab, MoreTab: moretab});
        if (subtab === "email") {
            this.setState({toggleEmail: toggleTab});
        } else if (subtab === "form") {
            this.setState({toggleForm: toggleTab});
        } else if (subtab === "chart") {
            this.setState({toggleChart: toggleTab});
        } else if (subtab === "table") {
            this.setState({toggleTable: toggleTab});
        } else if (subtab === "icon") {
            this.setState({toggleIcon: toggleTab});
        } else if (subtab === "map") {
            this.setState({toggleMap: toggleTab});
        } else if (subtab === "emt") {
            this.setState({toggleEmailTemp: toggleTab});
        } else {
        }
    };

    componentDidMount() {
        $(".navbar-toggle").on("click", function (event) {
            $(this).toggleClass("open");
            $("#navigation").slideToggle(400);
        });

        $("li.has-submenu #dest_id").on("click", function (event) {
            if ($(window).width() < 992) {
                $(".navbar-toggle").toggleClass("open");
                $("#navigation").slideToggle(400);
            }
        });

        $(".navigation-menu>li")
            .slice(-1)
            .addClass("last-elements");

        $('.navigation-menu li.has-submenu a[href="#"]').on("click", function (e) {
            if ($(window).width() < 992) {
                e.preventDefault();
                $(this)
                    .parent("li")
                    .toggleClass("open")
                    .find(".submenu:first")
                    .toggleClass("open");
            }
        });

        $(".toggle-search").on("click", function () {
            var targetId = $(this).data("target");
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass("open");
            }
        });
    }

    togglescreen(e) {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    handleLogout(){
        this.props.Logout();
        this.props.SetUser(null);
        fireApp.auth().signOut()
    }

    render() {
        return (
            <header id="topnav">
                <div className="topbar-main bg-dark">
                    <div className="container-fluid">
                        <div className="logo">
                            <Link to="/" className="logo"></Link>
                            <Link to="/" className="logo">
                                Tobim
                                {/* <img src="assets/images/logo-sm.png" alt="" height="30" /> */}
                            </Link>
                        </div>

                        <div className="menu-extras topbar-custom">
                            <div className="search-wrap" id="search-wrap">
                                <div className="search-bar">
                                    <input
                                        className="search-input"
                                        type="search"
                                        placeholder="Search"
                                    />
                                    <Link
                                        to="#"
                                        className="close-search toggle-search"
                                        data-target="#search-wrap"
                                    >
                                        <i className="mdi mdi-close-circle"></i>
                                    </Link>
                                </div>
                            </div>

                            <ul className="list-inline float-right mb-0">
                                <li className="list-inline-item dropdown notification-list">
                                    <Link
                                        className="nav-link waves-effect toggle-search"
                                        to="#"
                                        data-target="#search-wrap"
                                    >
                                        <i className="mdi mdi-magnify noti-icon"></i>
                                    </Link>
                                </li>
                                <li className="list-inline-item dropdown notification-list">
                                    {/* <Dropdown
                                        isOpen={this.state.dropdownOpenbadge}
                                        toggle={this.togglebadge}
                                    > */}
                                        {/* <DropdownToggle
                                            className="nav-link dropdown-toggle droptest arrow-none waves-effect"
                                            tag="a"
                                        >
                                            <i className="ion-ios7-bell noti-icon"></i>
                                            <span className="badge badge-pill badge-danger noti-icon-badge">
                                                3
                                            </span>
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu dropdown-menu-right dropdown-menu-lg">
                                            <h6 className="dropdown-item-text">
                                                Notifications (258)
                                            </h6>
                                            <DropdownItem>
                                                <Scrollbars
                                                    style={{height: "200px"}}
                                                    className="notification-item-list"
                                                >
                                                    <Link
                                                        id="ex"
                                                        to="#"
                                                        className="dropdown-item notify-item active"
                                                    >
                                                        <div className="notify-icon bg-success">
                                                            <i className="mdi mdi-cart-outline"></i>
                                                        </div>
                                                        <p className="notify-details">
                                                            Your order is placed
                                                            <span className="text-muted">
                                                                Dummy text of the printing and typesetting
                                                                industry.
                                                            </span>
                                                        </p>
                                                    </Link>

                                                    <Link
                                                        id="ex"
                                                        to="#"
                                                        className="dropdown-item notify-item"
                                                    >
                                                        <div className="notify-icon bg-warning">
                                                            <i className="mdi mdi-message-text-outline"></i>
                                                        </div>
                                                        <p className="notify-details">
                                                            New Message received
                                                            <span className="text-muted">
                                                                You have 87 unread messages
                                                            </span>
                                                        </p>
                                                    </Link>

                                                    <Link
                                                        id="ex"
                                                        to="#"
                                                        className="dropdown-item notify-item"
                                                    >
                                                        <div className="notify-icon bg-info">
                                                            <i className="mdi mdi-martini"></i>
                                                        </div>
                                                        <p className="notify-details">
                                                            Your item is shipped
                                                            <span className="text-muted">
                                                                It is a long established fact that a reader will
                                                            </span>
                                                        </p>
                                                    </Link>
                                                </Scrollbars>
                                                <Link
                                                    id="ex"
                                                    to="#"
                                                    className="dropdown-item text-center text-primary"
                                                >
                                                    View all
                                                    <i className="fi-arrow-right"></i>
                                                </Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown> */}
                                </li>
                                <li className="list-inline-item dropdown notification-list">
                                    <Dropdown
                                        isOpen={this.state.dropdownOpenprofile}
                                        toggle={this.toggleprofile}
                                    >
                                        <DropdownToggle
                                            className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user"
                                            tag="a"
                                        >
                                            <img
                                                src="assets/images/users/avatar-4.jpg"
                                                alt="user"
                                                className="rounded-circle"
                                            />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem>
                                                <i className="mdi mdi-account-circle m-r-5"></i>
                                                Profile
                                            </DropdownItem>
                                            <DropdownItem onClick={()=>this.handleLogout()}>
                                                <i className="mdi mdi-power text-danger"></i>
                                                Logout
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </li>
                                <li className="menu-item list-inline-item">
                                    <Link to="#" className="navbar-toggle nav-link">
                                        <div className="lines">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>

                <div className="navbar-custom">
                    <div className="container-fluid">
                        <div id="navigation">
                            <ul className="navigation-menu">
                                <li
                                    className={
                                        this.state.Tab === "index"
                                            ? "has-submenu active"
                                            : "has-submenu"
                                    }
                                >
                                    <Link
                                        to="/"
                                        onClick={() =>
                                            this.setState({
                                                dashboard_menu: !this.state.dashboard_menu,
                                            })
                                        }
                                    >
                                        <i className="mdi mdi-view-dashboard"></i>Accounts
                                    </Link>
                                </li>
                                <li
                                    className={
                                        this.state.Tab === "index"
                                            ? "has-submenu active"
                                            : "has-submenu"
                                    }
                                >
                                    <Link
                                        to="/walmart/global/search"
                                        onClick={() =>
                                            this.setState({
                                                dashboard_menu: !this.state.dashboard_menu,
                                            })
                                        }
                                    >
                                        <i className="mdi mdi-view-dashboard"></i>Global Walmart Search
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}


const mapStatetoProps = (state) => {
    return {
      topbar: state.ui_red.topbar,
      footer: state.ui_red.footer,
      loginpage: state.ui_red.loginpage,
      login: state.ui_red.login,
    };
  };
  

const mapDispatchtoProps = (dispatch) => {
    return {
      Logout: () => dispatch({ type: LOGIN, value: false }),
      SetUser: (content) => dispatch({ type: USER, payload:content }),
    };
  };

export default withRouter( connect(mapStatetoProps, mapDispatchtoProps)(topbar) );