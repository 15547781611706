import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';

class Icons_mobirise extends Component {

    render() {
        return (
            <AUX>
                <div className="wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="btn-group pull-right">
                                        <ol className="breadcrumb hide-phone p-0 m-0">
                                            <li className="breadcrumb-item">
                                                <Link to="#">Icons</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Mobirise Icons</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Mobirise Icons</h4>
                                </div>
                            </div>
                        </div>


                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">

                                        <h4 className="mt-0 header-title">Examples</h4>
                                        <p className="text-muted m-b-30 font-14">Use<code>&lt;i
                                            className="mbri-mobirise"&gt;&lt;/i&gt;</code>.
                                        </p>

                                        <div className="row icon-demo-content">
                                            <div className="col-12">
                                                <h6 className="m-b-20">Mobile-friendly</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-mobirise"></i>
                                                mbri-mobirise
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-mobile"></i>
                                                mbri-mobile
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-mobile2"></i>
                                                mbri-mobile2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-tablet"></i>
                                                mbri-tablet
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-tablet-vertical"></i>
                                                mbri-tablet-vertical
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-watch"></i>
                                                mbri-watch
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-devices"></i>
                                                mbri-devices
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-laptop"></i>
                                                mbri-laptop
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-desktop"></i>
                                                mbri-desktop
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-responsive"></i>
                                                mbri-responsive
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-touch-swipe"></i>
                                                mbri-touch-swipe
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-touch"></i>
                                                mbri-touch
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-browse"></i>
                                                mbri-browse
                                            </div>


                                            <div className="col-12">
                                                <h6 className="m-b-20">Shopping</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-shopping-bag"></i>
                                                mbri-shopping-bag
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-shopping-basket"></i>
                                                mbri-shopping-basket
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-shopping-cart"></i>
                                                mbri-shopping-cart
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-cart-add"></i>
                                                mbri-cart-add
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-cart-full"></i>
                                                mbri-cart-full
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-cash"></i>
                                                mbri-cash
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-delivery"></i>
                                                mbri-delivery
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-credit-card"></i>
                                                mbri-credit-card
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-sale"></i>
                                                mbri-sale
                                            </div>


                                            <div className="col-12">
                                                <h6 className="m-b-20">Navigation</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-left"></i>
                                                mbri-left
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-right"></i>
                                                mbri-right
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-up"></i>
                                                mbri-up
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-down"></i>
                                                mbri-down
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-arrow-prev"></i>
                                                mbri-arrow-prev
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-arrow-next"></i>
                                                mbri-arrow-next
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-arrow-up"></i>
                                                mbri-arrow-up
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-arrow-down"></i>
                                                mbri-arrow-down
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-plus"></i>
                                                mbri-plus
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-close"></i>
                                                mbri-close
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-menu"></i>
                                                mbri-menu
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-success"></i>
                                                mbri-success
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-play"></i>
                                                mbri-play
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-more-horizontal"></i>
                                                mbri-more-horizontal
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-more-vertical"></i>
                                                mbri-more-vertical
                                            </div>


                                            <div className="col-12">
                                                <h6 className="m-b-20">Symbols</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-home"></i>
                                                mbri-home
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-star"></i>
                                                mbri-star
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-hearth"></i>
                                                mbri-hearth
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-idea"></i>
                                                mbri-idea
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-magic-stick"></i>
                                                mbri-magic-stick
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-flag"></i>
                                                mbri-flag
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-target"></i>
                                                mbri-target
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-speed"></i>
                                                mbri-speed
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-help"></i>
                                                mbri-help
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-letter"></i>
                                                mbri-letter
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-info"></i>
                                                mbri-info
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-question"></i>
                                                mbri-question
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-features"></i>
                                                mbri-features
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-briefcase"></i>
                                                mbri-briefcase
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-sun"></i>
                                                mbri-sun
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-sun2"></i>
                                                mbri-sun2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-smile-face"></i>
                                                mbri-smile-face
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-sad-face"></i>
                                                mbri-sad-face
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-alert"></i>
                                                mbri-alert
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-timer"></i>
                                                mbri-timer
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-clock"></i>
                                                mbri-clock
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-growing-chart"></i>
                                                mbri-growing-chart
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-cursor-click"></i>
                                                mbri-cursor-click
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-protect"></i>
                                                mbri-protect
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-camera"></i>
                                                mbri-camera
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-globe"></i>
                                                mbri-globe
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-globe-2"></i>
                                                mbri-globe-2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-bookmark"></i>
                                                mbri-bookmark
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-rocket"></i>
                                                mbri-rocket
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-paper-plane"></i>
                                                mbri-paper-plane
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-hot-cup"></i>
                                                mbri-hot-cup
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-calendar"></i>
                                                mbri-calendar
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-search"></i>
                                                mbri-search
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-zoom-out"></i>
                                                mbri-zoom-out
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-android"></i>
                                                mbri-android
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-apple"></i>
                                                mbri-apple
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-windows"></i>
                                                mbri-windows
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-bootstrap"></i>
                                                mbri-bootstrap
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-github"></i>
                                                mbri-github
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-like"></i>
                                                mbri-like
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-wifi"></i>
                                                mbri-wifi
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-gift"></i>
                                                mbri-gift
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-database"></i>
                                                mbri-database
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-paperclip"></i>
                                                mbri-paperclip
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-print"></i>
                                                mbri-print
                                            </div>


                                            <div className="col-12">
                                                <h6 className="m-b-20">Editing</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-align-left"></i>
                                                mbri-align-left
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-align-center"></i>
                                                mbri-align-center
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-align-right"></i>
                                                mbri-align-right
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-align-justify"></i>
                                                mbri-align-justify
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-undo"></i>
                                                mbri-undo
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-redo"></i>
                                                mbri-redo
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-new-file"></i>
                                                mbri-new-file
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-file"></i>
                                                mbri-file
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-share"></i>
                                                mbri-share
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-save"></i>
                                                mbri-save
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-folder"></i>
                                                mbri-folder
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-opened-folder"></i>
                                                mbri-opened-folder
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-edit"></i>
                                                mbri-edit
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-edit2"></i>
                                                mbri-edit2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-change-style"></i>
                                                mbri-change-style
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-setting"></i>
                                                mbri-setting
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-setting2"></i>
                                                mbri-setting2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-setting3"></i>
                                                mbri-setting3
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-refresh"></i>
                                                mbri-refresh
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-trash"></i>
                                                mbri-trash
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-drag-n-drop"></i>
                                                mbri-drag-n-drop
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-drag-n-drop2"></i>
                                                mbri-drag-n-drop2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-up-down"></i>
                                                mbri-up-down
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-left-right"></i>
                                                mbri-left-right
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-layers"></i>
                                                mbri-layers
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-code"></i>
                                                mbri-code
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-bold"></i>
                                                mbri-bold
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-italic"></i>
                                                mbri-italic
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-underline"></i>
                                                mbri-underline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-add-submenu"></i>
                                                mbri-add-submenu
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-bulleted-list"></i>
                                                mbri-bulleted-list
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-numbered-list"></i>
                                                mbri-numbered-list
                                            </div>


                                            <div className="col-12">
                                                <h6 className="m-b-20">Site Elements</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-photo"></i>
                                                mbri-photo
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-photos"></i>
                                                mbri-photos
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-video-play"></i>
                                                mbri-video-play
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-video"></i>
                                                mbri-video
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-music"></i>
                                                mbri-music
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-pin"></i>
                                                mbri-pin
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-map-pin"></i>
                                                mbri-map-pin
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-chat"></i>
                                                mbri-chat
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-image-gallery"></i>
                                                mbri-image-gallery
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-image-slider"></i>
                                                mbri-image-slider
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-cust-feedback"></i>
                                                mbri-cust-feedback
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-contact-form"></i>
                                                mbri-contact-form
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-link"></i>
                                                mbri-link
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-unlink"></i>
                                                mbri-unlink
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-quote-left"></i>
                                                mbri-quote-left
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-quote-right"></i>
                                                mbri-quote-right
                                            </div>


                                            <div className="col-12">
                                                <h6 className="m-b-20">Account</h6>
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-user"></i>
                                                mbri-user
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-user2"></i>
                                                mbri-user2
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-users"></i>
                                                mbri-users
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-key"></i>
                                                mbri-key
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-lock"></i>
                                                mbri-lock
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-unlock"></i>
                                                mbri-unlock
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-login"></i>
                                                mbri-login
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-logout"></i>
                                                mbri-logout
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-cloud"></i>
                                                mbri-cloud
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-download"></i>
                                                mbri-download
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-upload"></i>
                                                mbri-upload
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-to-ftp"></i>
                                                mbri-to-ftp
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-to-local-drive"></i>
                                                mbri-to-local-drive
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-preview"></i>
                                                mbri-preview
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-error"></i>
                                                mbri-error
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-pages"></i>
                                                mbri-pages
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-sites"></i>
                                                mbri-sites
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-extension"></i>
                                                mbri-extension
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-website-theme"></i>
                                                mbri-website-theme
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="mbri-update"></i>
                                                mbri-update
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}

export default Icons_mobirise;   