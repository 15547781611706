import React, { useState } from "react";
import { saveAccount } from "../../../fetch/fetch_accounts";
import "./AddAccount.sass";
import FormAmazonSetting from "./FormAmazonSetting";
import FormWalmartSetting from "./FormWalmartSetting";

export default function AddAccount({ onSaved, account }) {

  const amazon = account?.amazonSetting 
  console.log(account);
  const [name, setname] = useState( account?.name ? account?.name :"");

  const [accesskey, setaccesskey] = useState(amazon?.accessKey ? amazon?.accessKey : "");
  const [secret, setsecret] = useState(amazon?.secret ? amazon?.secret: "");
  const [sellerId, setseller] = useState(amazon?.sellerId? amazon?.sellerId: "");
  const [market, setmarket] = useState(amazon?.market? amazon?.market: "");
  const [token, settoken] = useState(amazon?.token? amazon?.token: "");
  const [app, setapp] = useState(amazon?.appName? amazon?.appName: "");
  const [version, setversion] = useState(amazon?.version? amazon?.version: "");

  const [emails, setemails] = useState(account?.walmartSetting.emails ? account?.walmartSetting.emails :  [{address:"", password:""}]);
  const [error, setError] = useState([]); 
  const addHandler = async () => {
    if (
      !name ||
      !emails ||
      !accesskey ||
      !secret ||
      !sellerId ||
      !market ||
      !app ||
      !version ||
      !token
    ) {
      return;
    }
    setError({'message': "We're adding your account and saving your mails, please wait a moment"});
    try {
      const response = await saveAccount({
        name,
        emails,
        accesskey,
        secret,
        sellerId,
        market,
        app,
        version,
        token,
      });
      if (response.status === 200) {
        const json = await response.json();
        if (!json.error){
          onSaved(json);
        }else{
          setError(json);
        }

      }
    } catch (e) {
      console.error(e);
    }
  };


  const popEmail = (i)=>{
    emails.splice(i, 1)
    setemails([...emails])
 }

  const addEmail = ()=>{
     setemails([...emails, {address:"", password:""}])
    console.log(emails);
  }

  const handleChangeEmail = (i, address, password)=>{
    if(address){
      emails[i].address = address
    }
    
    if(password || password===''){
      emails[i].password = password
    }
    setemails([...emails])
  }
  return (
    <div className="text-capitalize px-5">
      <section>
        <header>
          <h2>Account Information</h2>
        </header>
        <article>
          <div className="d-flex">
            <div className="input-section">
              <label htmlFor="name" className="d-block">
                Name
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
                className="form-control input"
              />
            </div>
          </div>
        </article>
      </section>

      <hr />

      <div className="row">
        <FormAmazonSetting
          accesskey={accesskey}
          setaccesskey={setaccesskey}
          secret={secret}
          setsecret={setsecret}
          sellerId={sellerId}
          setseller={setseller}
          market={market}
          setmarket={setmarket}
          app={app}
          setapp={setapp}
          version={version}
          setversion={setversion}
          token={token}
          settoken={settoken}
        />
        <FormWalmartSetting
          emails={emails}
          setemail={handleChangeEmail}
          onAddEmais={addEmail}
          onPopEmail={popEmail}
        />
      </div>

      <div>
        <button
          className="btn btn-success mt-3"
          onClick={() => addHandler().then()}
        >
          Add Account
        </button>
        <p>{error.message}</p>
      </div>
    </div>
  );
}
