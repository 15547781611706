import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';

class Icons_typicons extends Component {

    render() {
        return (
            <AUX>
                <div className="wrapper">
                    <div className="container-fluid">

                        <div className="row">
                            <div className="col-sm-12">
                                <div className="page-title-box">
                                    <div className="btn-group pull-right">
                                        <ol className="breadcrumb hide-phone p-0 m-0">
                                            <li className="breadcrumb-item">
                                                <Link to="#">Icons</Link>
                                            </li>
                                            <li className="breadcrumb-item active">Typicons</li>
                                        </ol>
                                    </div>
                                    <h4 className="page-title">Typicons Icons</h4>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <div className="card m-b-20">
                                    <div className="card-body">

                                        <h4 className="mt-0 header-title">Examples</h4>
                                        <p className="text-muted m-b-30 font-14">Use<code>&lt;i className="typcn
                                            typcn-chart-pie-outline
                                            "&gt;&lt;/i&gt;</code>.
                                        </p>

                                        <div className="row icon-demo-content">
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-chart-pie-outline"></i>
                                                typcn typcn-chart-pie-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-chart-pie"></i>
                                                typcn typcn-chart-pie
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-chevron-left-outline"></i>
                                                typcn typcn-chevron-left-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-chevron-left"></i>
                                                typcn typcn-chevron-left
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-chevron-right-outline"></i>
                                                typcn typcn-chevron-right-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-chevron-right"></i>
                                                typcn typcn-chevron-right
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-clipboard"></i>
                                                typcn typcn-clipboard
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-cloud-storage"></i>
                                                typcn typcn-cloud-storage
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-cloud-storage-outline"></i>
                                                typcn typcn-cloud-storage-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-code-outline"></i>
                                                typcn typcn-code-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-code"></i>
                                                typcn typcn-code
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-coffee"></i>
                                                typcn typcn-coffee
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-cog-outline"></i>
                                                typcn typcn-cog-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-cog"></i>
                                                typcn typcn-cog
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-compass"></i>
                                                typcn typcn-compass
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-contacts"></i>
                                                typcn typcn-contacts
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-credit-card"></i>
                                                typcn typcn-credit-card
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-css3"></i>
                                                typcn typcn-css3
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-database"></i>
                                                typcn typcn-database
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-delete-outline"></i>
                                                typcn typcn-delete-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-delete"></i>
                                                typcn typcn-delete
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-device-desktop"></i>
                                                typcn typcn-device-desktop
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-device-laptop"></i>
                                                typcn typcn-device-laptop
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-device-phone"></i>
                                                typcn typcn-device-phone
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-device-tablet"></i>
                                                typcn typcn-device-tablet
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-directions"></i>
                                                typcn typcn-directions
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-divide-outline"></i>
                                                typcn typcn-divide-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-divide"></i>
                                                typcn typcn-divide
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-document-add"></i>
                                                typcn typcn-document-add
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-document-delete"></i>
                                                typcn typcn-document-delete
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-document-text"></i>
                                                typcn typcn-document-text
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-document"></i>
                                                typcn typcn-document
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-download-outline"></i>
                                                typcn typcn-download-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-download"></i>
                                                typcn typcn-download
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-dropbox"></i>
                                                typcn typcn-dropbox
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-edit"></i>
                                                typcn typcn-edit
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-eject-outline"></i>
                                                typcn typcn-eject-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-eject"></i>
                                                typcn typcn-eject
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-equals-outline"></i>
                                                typcn typcn-equals-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-equals"></i>
                                                typcn typcn-equals
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-export-outline"></i>
                                                typcn typcn-export-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-export"></i>
                                                typcn typcn-export
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-eye-outline"></i>
                                                typcn typcn-eye-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-eye"></i>
                                                typcn typcn-eye
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-feather"></i>
                                                typcn typcn-feather
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-film"></i>
                                                typcn typcn-film
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-filter"></i>
                                                typcn typcn-filter
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flag-outline"></i>
                                                typcn typcn-flag-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flag"></i>
                                                typcn typcn-flag
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flash-outline"></i>
                                                typcn typcn-flash-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flash"></i>
                                                typcn typcn-flash
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flow-children"></i>
                                                typcn typcn-flow-children
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flow-merge"></i>
                                                typcn typcn-flow-merge
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flow-parallel"></i>
                                                typcn typcn-flow-parallel
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-flow-switch"></i>
                                                typcn typcn-flow-switch
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-folder-add"></i>
                                                typcn typcn-folder-add
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-folder-delete"></i>
                                                typcn typcn-folder-delete
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-folder-open"></i>
                                                typcn typcn-folder-open
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-folder"></i>
                                                typcn typcn-folder
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-gift"></i>
                                                typcn typcn-gift
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-globe-outline"></i>
                                                typcn typcn-globe-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-globe"></i>
                                                typcn typcn-globe
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-group-outline"></i>
                                                typcn typcn-group-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-group"></i>
                                                typcn typcn-group
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-headphones"></i>
                                                typcn typcn-headphones
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-heart-full-outline"></i>
                                                typcn typcn-heart-full-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-heart-half-outline"></i>
                                                typcn typcn-heart-half-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-heart-outline"></i>
                                                typcn typcn-heart-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-heart"></i>
                                                typcn typcn-heart
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-home-outline"></i>
                                                typcn typcn-home-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-home"></i>
                                                typcn typcn-home
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-html5"></i>
                                                typcn typcn-html5
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-image-outline"></i>
                                                typcn typcn-image-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-image"></i>
                                                typcn typcn-image
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-infinity-outline"></i>
                                                typcn typcn-infinity-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-infinity"></i>
                                                typcn typcn-infinity
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-info-large-outline"></i>
                                                typcn typcn-info-large-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-info-large"></i>
                                                typcn typcn-info-large
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-info-outline"></i>
                                                typcn typcn-info-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-info"></i>
                                                typcn typcn-info
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-input-checked-outline"></i>
                                                typcn typcn-input-checked-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-input-checked"></i>
                                                typcn typcn-input-checked
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-key-outline"></i>
                                                typcn typcn-key-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-key"></i>
                                                typcn typcn-key
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-keyboard"></i>
                                                typcn typcn-keyboard
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-leaf"></i>
                                                typcn typcn-leaf
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-lightbulb"></i>
                                                typcn typcn-lightbulb
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-link-outline"></i>
                                                typcn typcn-link-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-link"></i>
                                                typcn typcn-link
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-location-arrow-outline"></i>
                                                typcn typcn-location-arrow-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-location-arrow"></i>
                                                typcn typcn-location-arrow
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-location-outline"></i>
                                                typcn typcn-location-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-location"></i>
                                                typcn typcn-location
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-lock-closed-outline"></i>
                                                typcn typcn-lock-closed-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-lock-closed"></i>
                                                typcn typcn-lock-closed
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-lock-open-outline"></i>
                                                typcn typcn-lock-open-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-lock-open"></i>
                                                typcn typcn-lock-open
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-mail"></i>
                                                typcn typcn-mail
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-map"></i>
                                                typcn typcn-map
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-eject-outline"></i>
                                                typcn typcn-media-eject-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-eject"></i>
                                                typcn typcn-media-eject
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-fast-forward-outline"></i>
                                                typcn typcn-media-fast-forward-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-fast-forward"></i>
                                                typcn typcn-media-fast-forward
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-pause-outline"></i>
                                                typcn typcn-media-pause-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-pause"></i>
                                                typcn typcn-media-pause
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-play-outline"></i>
                                                typcn typcn-media-play-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-play-reverse-outline"></i>
                                                typcn typcn-media-play-reverse-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-play-reverse"></i>
                                                typcn typcn-media-play-reverse
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-play"></i>
                                                typcn typcn-media-play
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-record-outline"></i>
                                                typcn typcn-media-record-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-record"></i>
                                                typcn typcn-media-record
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-rewind-outline"></i>
                                                typcn typcn-media-rewind-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-rewind"></i>
                                                typcn typcn-media-rewind
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-stop-outline"></i>
                                                typcn typcn-media-stop-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-media-stop"></i>
                                                typcn typcn-media-stop
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-message-typing"></i>
                                                typcn typcn-message-typing
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-message"></i>
                                                typcn typcn-message
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-messages"></i>
                                                typcn typcn-messages
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-microphone-outline"></i>
                                                typcn typcn-microphone-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-microphone"></i>
                                                typcn typcn-microphone
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-minus-outline"></i>
                                                typcn typcn-minus-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-minus"></i>
                                                typcn typcn-minus
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-mortar-board"></i>
                                                typcn typcn-mortar-board
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-news"></i>
                                                typcn typcn-news
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-notes-outline"></i>
                                                typcn typcn-notes-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-notes"></i>
                                                typcn typcn-notes
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pen"></i>
                                                typcn typcn-pen
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pencil"></i>
                                                typcn typcn-pencil
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-phone-outline"></i>
                                                typcn typcn-phone-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-phone"></i>
                                                typcn typcn-phone
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pi-outline"></i>
                                                typcn typcn-pi-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pi"></i>
                                                typcn typcn-pi
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pin-outline"></i>
                                                typcn typcn-pin-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pin"></i>
                                                typcn typcn-pin
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-pipette"></i>
                                                typcn typcn-pipette
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-plane-outline"></i>
                                                typcn typcn-plane-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-plane"></i>
                                                typcn typcn-plane
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-plug"></i>
                                                typcn typcn-plug
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-plus-outline"></i>
                                                typcn typcn-plus-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-plus"></i>
                                                typcn typcn-plus
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-point-of-interest-outline"></i>
                                                typcn typcn-point-of-interest-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-point-of-interest"></i>
                                                typcn typcn-point-of-interest
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-power-outline"></i>
                                                typcn typcn-power-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-power"></i>
                                                typcn typcn-power
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-printer"></i>
                                                typcn typcn-printer
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-puzzle-outline"></i>
                                                typcn typcn-puzzle-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-puzzle"></i>
                                                typcn typcn-puzzle
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-radar-outline"></i>
                                                typcn typcn-radar-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-radar"></i>
                                                typcn typcn-radar
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-refresh-outline"></i>
                                                typcn typcn-refresh-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-refresh"></i>
                                                typcn typcn-refresh
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-rss-outline"></i>
                                                typcn typcn-rss-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-rss"></i>
                                                typcn typcn-rss
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-scissors-outline"></i>
                                                typcn typcn-scissors-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-scissors"></i>
                                                typcn typcn-scissors
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-shopping-bag"></i>
                                                typcn typcn-shopping-bag
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-shopping-cart"></i>
                                                typcn typcn-shopping-cart
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-at-circular"></i>
                                                typcn typcn-social-at-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-dribbble-circular"></i>
                                                typcn typcn-social-dribbble-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-dribbble"></i>
                                                typcn typcn-social-dribbble
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-facebook-circular"></i>
                                                typcn typcn-social-facebook-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-facebook"></i>
                                                typcn typcn-social-facebook
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-flickr-circular"></i>
                                                typcn typcn-social-flickr-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-flickr"></i>
                                                typcn typcn-social-flickr
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-github-circular"></i>
                                                typcn typcn-social-github-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-github"></i>
                                                typcn typcn-social-github
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-google-plus-circular"></i>
                                                typcn typcn-social-google-plus-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-google-plus"></i>
                                                typcn typcn-social-google-plus
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-instagram-circular"></i>
                                                typcn typcn-social-instagram-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-instagram"></i>
                                                typcn typcn-social-instagram
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-last-fm-circular"></i>
                                                typcn typcn-social-last-fm-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-last-fm"></i>
                                                typcn typcn-social-last-fm
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-linkedin-circular"></i>
                                                typcn typcn-social-linkedin-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-linkedin"></i>
                                                typcn typcn-social-linkedin
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-pinterest-circular"></i>
                                                typcn typcn-social-pinterest-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-pinterest"></i>
                                                typcn typcn-social-pinterest
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-skype-outline"></i>
                                                typcn typcn-social-skype-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-skype"></i>
                                                typcn typcn-social-skype
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-tumbler-circular"></i>
                                                typcn typcn-social-tumbler-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-tumbler"></i>
                                                typcn typcn-social-tumbler
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-twitter-circular"></i>
                                                typcn typcn-social-twitter-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-twitter"></i>
                                                typcn typcn-social-twitter
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-vimeo-circular"></i>
                                                typcn typcn-social-vimeo-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-vimeo"></i>
                                                typcn typcn-social-vimeo
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-youtube-circular"></i>
                                                typcn typcn-social-youtube-circular
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-social-youtube"></i>
                                                typcn typcn-social-youtube
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-sort-alphabetically-outline"></i>
                                                typcn typcn-sort-alphabetically-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-sort-alphabetically"></i>
                                                typcn typcn-sort-alphabetically
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-sort-numerically-outline"></i>
                                                typcn typcn-sort-numerically-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-sort-numerically"></i>
                                                typcn typcn-sort-numerically
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-spanner-outline"></i>
                                                typcn typcn-spanner-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-spanner"></i>
                                                typcn typcn-spanner
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-spiral"></i>
                                                typcn typcn-spiral
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-star-full-outline"></i>
                                                typcn typcn-star-full-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-star-half-outline"></i>
                                                typcn typcn-star-half-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-star-half"></i>
                                                typcn typcn-star-half
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-star-outline"></i>
                                                typcn typcn-star-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-star"></i>
                                                typcn typcn-star
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-starburst-outline"></i>
                                                typcn typcn-starburst-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-starburst"></i>
                                                typcn typcn-starburst
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-stopwatch"></i>
                                                typcn typcn-stopwatch
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-support"></i>
                                                typcn typcn-support
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-tabs-outline"></i>
                                                typcn typcn-tabs-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-tag"></i>
                                                typcn typcn-tag
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-tags"></i>
                                                typcn typcn-tags
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-large-outline"></i>
                                                typcn typcn-th-large-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-large"></i>
                                                typcn typcn-th-large
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-list-outline"></i>
                                                typcn typcn-th-list-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-list"></i>
                                                typcn typcn-th-list
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-menu-outline"></i>
                                                typcn typcn-th-menu-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-menu"></i>
                                                typcn typcn-th-menu
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-small-outline"></i>
                                                typcn typcn-th-small-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-th-small"></i>
                                                typcn typcn-th-small
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-thermometer"></i>
                                                typcn typcn-thermometer
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-thumbs-down"></i>
                                                typcn typcn-thumbs-down
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-thumbs-ok"></i>
                                                typcn typcn-thumbs-ok
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-thumbs-up"></i>
                                                typcn typcn-thumbs-up
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-tick-outline"></i>
                                                typcn typcn-tick-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-tick"></i>
                                                typcn typcn-tick
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-ticket"></i>
                                                typcn typcn-ticket
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-time"></i>
                                                typcn typcn-time
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-times-outline"></i>
                                                typcn typcn-times-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-times"></i>
                                                typcn typcn-times
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-trash"></i>
                                                typcn typcn-trash
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-tree"></i>
                                                typcn typcn-tree
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-upload-outline"></i>
                                                typcn typcn-upload-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-upload"></i>
                                                typcn typcn-upload
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-user-add-outline"></i>
                                                typcn typcn-user-add-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-user-add"></i>
                                                typcn typcn-user-add
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-user-delete-outline"></i>
                                                typcn typcn-user-delete-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-user-delete"></i>
                                                typcn typcn-user-delete
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-user-outline"></i>
                                                typcn typcn-user-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-user"></i>
                                                typcn typcn-user
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-vendor-android"></i>
                                                typcn typcn-vendor-android
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-vendor-apple"></i>
                                                typcn typcn-vendor-apple
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-vendor-microsoft"></i>
                                                typcn typcn-vendor-microsoft
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-video-outline"></i>
                                                typcn typcn-video-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-video"></i>
                                                typcn typcn-video
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-volume-down"></i>
                                                typcn typcn-volume-down
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-volume-mute"></i>
                                                typcn typcn-volume-mute
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-volume-up"></i>
                                                typcn typcn-volume-up
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-volume"></i>
                                                typcn typcn-volume
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-warning-outline"></i>
                                                typcn typcn-warning-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-warning"></i>
                                                typcn typcn-warning
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-watch"></i>
                                                typcn typcn-watch
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-waves-outline"></i>
                                                typcn typcn-waves-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-waves"></i>
                                                typcn typcn-waves
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-cloudy"></i>
                                                typcn typcn-weather-cloudy
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-downpour"></i>
                                                typcn typcn-weather-downpour
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-night"></i>
                                                typcn typcn-weather-night
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-partly-sunny"></i>
                                                typcn typcn-weather-partly-sunny
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-shower"></i>
                                                typcn typcn-weather-shower
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-snow"></i>
                                                typcn typcn-weather-snow
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-stormy"></i>
                                                typcn typcn-weather-stormy
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-sunny"></i>
                                                typcn typcn-weather-sunny
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-windy-cloudy"></i>
                                                typcn typcn-weather-windy-cloudy
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-weather-windy"></i>
                                                typcn typcn-weather-windy
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-wi-fi-outline"></i>
                                                typcn typcn-wi-fi-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-wi-fi"></i>
                                                typcn typcn-wi-fi
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-wine"></i>
                                                typcn typcn-wine
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-world-outline"></i>
                                                typcn typcn-world-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-world"></i>
                                                typcn typcn-world
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-zoom-in-outline"></i>
                                                typcn typcn-zoom-in-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-zoom-in"></i>
                                                typcn typcn-zoom-in
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-zoom-out-outline"></i>
                                                typcn typcn-zoom-out-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-zoom-out"></i>
                                                typcn typcn-zoom-out
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-zoom-outline"></i>
                                                typcn typcn-zoom-outline
                                            </div>
                                            <div className="col-xl-3 col-md-4 col-sm-6">
                                                <i className="typcn typcn-zoom"></i>
                                                typcn typcn-zoom
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </AUX>
        );
    }
}

export default Icons_typicons;   