import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {createStore, combineReducers} from 'redux';
import '../node_modules/fullcalendar/dist/fullcalendar.min.js';
import UIreducer from '././modules/template/store/reducers/reducer';
import {Provider} from 'react-redux';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { firebaseConfig } from './firebase';

const rootReducer = combineReducers({
    ui_red: UIreducer,
});

export const fireApp = firebase.initializeApp(firebaseConfig);


const store = createStore(rootReducer);
const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);


ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
