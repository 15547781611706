import React, { useEffect, useState } from "react";
import TableCollapse from "../../TableCollapse/TableCollapse";
import DetailItem from "../DetailItem/DetailItem";

export default function AmazonFees({fees}) {
    const keys = fees ? Object.keys(fees) : [];
    const[total, setTotal] = useState(0);
    useEffect(() => {
        let total = 0; 
        for (let i = 0; i < keys.length ; i++) {
            total = total + parseFloat(fees[i]['FeeAmount']['CurrencyAmount']) 
            setTotal(total); 
        }
    }, [])
    
    const feeName = (fee) => { 
        switch (fee) {
            case '0':
                return "Comission";
            case '1':
                return "Fixed Closing";
            case '2':
                return "Gift Wrap Commission";
            case '3':
                return "Shipping HB";
            case '4':
                return "Variable Closing Fee";
            default:
                return fee;
        }
    };

    
    return (
        <TableCollapse name="fees" total={total}>
            {keys.map((key) => (

                <React.Fragment key={key}>
                    {key !== 'total' &&
                    <DetailItem key={key} name={feeName(key)} value={fees[key]['FeeAmount']['CurrencyAmount']}/>
                    }
                </React.Fragment>

            ))}
        </TableCollapse>
    );
}
