import React, { Component } from "react";
import Layout from "./modules/template/components/Layout/Layout";
import { Route, Switch } from "react-router-dom";
import mainbuilder from "./modules/template/containers/mainbuilder/mainbuilder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AUX from "./modules/template/hoc/Aux_";
import { fireApp } from ".";
import { LOGIN, USER } from "./modules/template/store/action";
import Login from "./modules/tobim/pages/Login";

class App extends Component {
  componentDidMount() {
    
    fireApp.auth().onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const token = await userAuth.getIdTokenResult();
        this.props.UpdateLogin();
        this.props.SetUser(token)
        return
      }
    });
  }

  render() {
    let layout = null;

    layout = (
      <Layout
        topbar={this.props.topbar}
        footer={false}
        loginpage={this.props.loginpage}
      >
        <Switch>
          <Route path="/" component={mainbuilder} />
        </Switch>
      </Layout>
    );
    return <AUX>
       {this.props.login? layout: <Login/>}
      
      </AUX>;
  }
}

const mapStatetoProps = (state) => {
  return {
    topbar: state.ui_red.topbar,
    footer: state.ui_red.footer,
    loginpage: state.ui_red.loginpage,
    login: state.ui_red.login,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: LOGIN, value: true }),
    UpdateLoginAgain: () => dispatch({ type: LOGIN, value: false }),
    SetUser: content => dispatch({ type: USER, payload:content }),
  };
};

export default withRouter(connect(mapStatetoProps, mapDispatchtoProps)(App));
