import React from "react";
import TableCollapse from "../../TableCollapse/TableCollapse";
import DetailItem from "../DetailItem/DetailItem";

export default function AmazonCharges({charges}) {
    
    const principal = parseFloat(charges?.[0].ChargeAmount?.CurrencyAmount);
    const giftwrap = parseFloat(charges?.[2].ChargeAmount?.CurrencyAmount);
    const shipping = parseFloat(charges?.[4].ChargeAmount?.CurrencyAmount);
    const shippingTax = parseFloat(charges?.[5].ChargeAmount?.CurrencyAmount);
    const giftwraptax = parseFloat(charges?.[3].ChargeAmount?.CurrencyAmount);
    const tax = parseFloat(charges?.[1].ChargeAmount?.CurrencyAmount);
    const total = principal + giftwrap + shipping + shippingTax + giftwraptax + tax;
    

    
    return (
        <React.Fragment>
            <TableCollapse name="charges" total={total} startOpen={true}>
                <DetailItem name="Principal" value={principal}/>
                <DetailItem name="gift wrap" value={giftwrap}/>
                <DetailItem name="Shipping" value={shipping}/>
                <DetailItem name="shipping Tax" value={shippingTax}/>
                <DetailItem name="giftwrap tax" value={giftwraptax}/>
                <DetailItem name="Tax" value={tax}/>
            </TableCollapse>

        </React.Fragment>
    );
}
