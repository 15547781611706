import React, {useState} from "react";
import {currencyFormat} from "../../../../utils/currency";
import IconCollapse from "../IconCollapse/IconCollapse";

export default function TableCollapse({name, total, children, startOpen}) {
    const [open, setopen] = useState(startOpen);
    return (
        <React.Fragment>
            <tr>
                <th>
                    {total !== 0 ? (
                        <React.Fragment>
                            <IconCollapse open={open} onOpen={() => setopen(!open)}/>
                            {name}
                        </React.Fragment>
                    ) : (
                        <span className="pl-4">{name}</span>
                    )}
                </th>
                <th id={`total-${name}`} className="text-right">{currencyFormat(total)}</th>
            </tr>
            {open && children}
        </React.Fragment>
    );
}
