import  config  from "../../../config";

export async function getAccounts() {
  return fetch(`${config.apiPython}/accounts/`);
}


export async function deleteAccount(id){
  return fetch(`${config.apiPython}/delete_account/${id}`);
}


export async function fetchAccountSync(id) {
  return fetch(`${config.api}/accounts/sync/${id}`);
}


export async function saveAccount({
  name,
  emails,
  password,
  accesskey,
  secret,
  sellerId,
  market,
  app,
  version,
  token
}) {

  const account = {
    name,
    amazonSetting: {
      accessKey:accesskey,
      secret,
      password,
      sellerId,
      market,
      appName: app,
      version,
      token
    },
    walmartSetting:{
      emails,
      password,
    },
    activate: true
  };
  
  return fetch(`${config.apiPython}/accounts`, {
    method: "POST",
    body: JSON.stringify(account),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
}
