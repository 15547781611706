// const api = 'http://127.0.0.1:5000/api/v1/orders/'
import  config  from "../../../config";
export async function getOrders(keyword, page, size) {
    return fetch(`${config.apiPython}/walmart-orders/${page}/${size}`, {
        method: "POST",
        body: JSON.stringify({
            keyword: keyword
        }),
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        },
    });
}

export function searchUnmatchedOrders(keyword){
    return fetch(`${config.apiPython}/search-unmatched-walmart/${keyword}/1/100`)
}

export async function getWalmartOrder(id){
    return fetch(`${config.apiPython}/walmart-order/${id}`); 
}

/*export function exportReport(account_id, account_name){
    //return fetch(`${config.apiPython}/export-to-csv/${account_id}`);
    window.location.href = config.apiPython + "/export-to-csv/" + account_id + "/" + account_name;
}*/



export function exportReport(filename, mimeType, account_id, account_name){
    return (fetch(`${config.apiPython}/export-to-csv/${account_id}/${account_name}`)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}


