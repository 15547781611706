import PageTemplate from "../layout/PageTemplate";
import React, { useState, useEffect } from "react";
import { getUnmatchedAmazonOrders, searchUnmatchedOrders } from "../fetch/amazon_orders";
import { withRouter } from "react-router-dom";
import Pagination from "../components/amazon/pagination/Pagination";
import { currencyFormat } from "../../../utils/currency";
import {exportReport} from "../fetch/amazon_orders";
import UnmatchedAmazonOrderTable from "../components/UnmatchedAmazonOrderTable/UnmatchedAmazonOrderTable";
const AmazonUnmatched = () => {
  const [orders, setOrders] = useState([]);
  const [size, setSize] = useState(20); 
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(1); 

  useEffect(()=>{
    getOrders(page, size);
    console.log(orders); 
  }, [])

  const getOrders = async (page, size) =>{
    const response = await getUnmatchedAmazonOrders(
      page,
      size
    );

    if (response.status === 200) {
      const data = await response.json();
      setOrders(data);
      setTotalPages(data[0].totalpages);
      setTotalElements(data[0].totalElements);
      console.log(orders);
    }
  }
  
  const handleNext = () => {
    setPage(page+1)
    setOrders(page+1, size)
    
  };

  const handlePrev = () => {
    setPage(page-1)
    setOrders(page-1, size)
  
  };

  const handleSelect = (selected) => {
    setPage(selected);
    getOrders(selected, size);
  };

  const handleExport = () => {
    exportReport('hello.txt','text/plain').then((blob)=> downloadReport(blob)); 
  }

  const downloadReport = (blob) => {
      // 2. Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `AmazonUnmatched-report ${new Date().toLocaleString()}.csv`);
      // 3. Append to html page
      document.body.appendChild(link);
      // 4. Force download
      link.click();
      // 5. Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    const handlerChange = async (event) => {
      // tomar el valor a buscar
      const value = event.target ? event.target.value : event;
  
      // ir a el backend y recibir los resultados
      const response = await searchUnmatchedOrders(value);
      const json = await response.json();
      /*if (json.length === 0 && !value) {
        this.setState({show: this.state.orders})
        return;
      }
      // // actualizar el listado de resultado
      this.setState({show: json})*/
      setOrders(json);
      setTotalPages(json[0].totalpages);
      setTotalElements(json[0].totalElements);
    };
  

  
    return (
      <PageTemplate title="Unmatched Amazon Orders" account="">
        <UnmatchedAmazonOrderTable orders = {orders} totalPages={totalPages} totalElements={totalElements} page={page} size={size} handleNext={handleNext} handlePrev={handlePrev} handleSelect={handleSelect} handleExport={handleExport} handlerChange={handlerChange}/>
      </PageTemplate>
    );
  
}

export default withRouter(AmazonUnmatched);

