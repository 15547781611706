import config from "../../../config";

export async function getAmazonOrders(account, page, size){
    return fetch(`${config.api}/amazon_orders//matched?page=${page}&size=${size}&account=${account}`)
}

export async function getMatchedOrders(account, page, size){
    return fetch(`${config.apiPython}/matched-orders/${account}/${page}/${size}`)
}

export async function getUnmatchedWalmartOrders(account, page, size){
    return fetch(`${config.apiPython}/unmatched-orders/${account}/${page}/${size}`)
}

export async function getUnmatchedAmazonOrders(page, size){
    return fetch(`${config.apiPython}/amazon-unmatched-orders/${page}/${size}`)
}

export function searchOrders(keyword){
    return fetch(`${config.apiPython}/search-matched/${keyword}/1/100`)
}

export function searchUnmatchedOrders(keyword){
    return fetch(`${config.apiPython}/search-unmatched-amazon/${keyword}/1/100`)
}


export function uploadReport(file){
    if(!file){
        return
    }
    const data = new FormData()
    data.append('report', file)
    
    return fetch(`${config.apiPython}/upload-report`, {
        method: "POST",
        body:data,     
    }).then(res => {
        return res.json()
    })
}


/*export function exportReport(account){
    return fetch(`${config.api}/amazon_orders//export?account=${account}`)
}*/

export function exportReport(filename, mimeType){
    return (fetch(`${config.apiPython}/export-to-csv/amazon_unmatched`)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}

export function exportReportMatched(filename, mimeType, account_id, account_name){
    return (fetch(`${config.apiPython}/export-to-csv/matched/${account_id}/${account_name}`)
        .then(function(res){return res.arrayBuffer();})
        .then(function(buf){return new File([buf], filename,{type:mimeType});})
    );
}






