import React, {useState, useEffect} from 'react';
import { getOrders } from '../fetch/walmart_orders';
import PageTemplate from '../layout/PageTemplate';
import Pagination from "../components/amazon/pagination/Pagination";

export default function WalmartGlobalSearch(){
    const [size, setSize]= useState(100);
    const [page, setPage] = useState(1); 
    const [totalElements, setTotalElements] = useState(null);
    const [totalPages, setTotalPages] = useState(null);  
    const[orders, setOrders] = useState([]); 
    const[keyword, setKeyword] = useState('');
    useEffect(() => {
        getInfo(); 
      }, [page]);

      const getInfo = async () => {
        
        const response = await getOrders(keyword, page, size);
          if (response.status === 200) {
            const json = await response.json();
            setOrders(json);
            setTotalElements(json[0]?.totalElements); 
            setTotalPages(json[0]?.totalpages); 
            console.log(json); 
        }
      }

      const handleNext = () => {
        setPage(page+1);    
      };
    
      const handlePrev = () => {
        setPage(page-1);
      };
    
      const handleSelect = (selected) => {
        setPage(selected)
      };

   

    return(
        <PageTemplate title="Walmart Global search" account="">
            <React.Fragment>
                <div className="card">
                    <div className="card-body">
                        <form className="form-horizontal m-t-30" onSubmit={e=>e.preventDefault()} >
                            <div className="form-group">
                            <label htmlFor="search">Search</label>
                            <input
                                type="text"
                                className="form-control"
                                id="search"
                                placeholder="Enter Search"
                                onChange={(e)=>setKeyword(e.target.value)}
                            />
                            </div>
                            <div className="form-group row">
                            <div className="col-sm-6">
                                <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                                onClick={() => {getInfo(); setPage(1)}}
                                >
                                Search
                                </button>
                            </div>
                            </div>                        
                        </form>
                    </div>
                </div>
                <table className="table  text-capitalize table-bordered">
                    <thead className="thead-light">
                    <tr>
                        <th>ID</th>
                        <th>Customer</th>
                        <th>State</th>
                        <th>City</th>
                        <th>Address</th>
                        <th>ZIP</th>
                        
                    </tr>
                    </thead>
                    <tbody>
                    {orders.map((o) => (
                        <tr key={o['_id']}>
                            <td>{o['_id']}</td>
                            <td>{o.customer}</td>
                            <td>{o?.address?.state}</td>
                            <td>{o?.address?.city}</td>
                            <td>{o?.address?.address}</td>
                            <td>{o?.address?.postalCode}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <Pagination
                totalElements={totalElements}
                totalPages={totalPages}
                page={page}
                onNext={handleNext}
                onPrev={handlePrev}
                onSelect={handleSelect}
                />
            </React.Fragment>
        </PageTemplate>
    );
}
