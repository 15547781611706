import {currencyFormat} from "../../../../../utils/currency";
import React from 'react'

export default function DetailItem(props) {
    return (
        <tr>
            <td className="pl-5 font-14">{props.name}</td>
            <td className="text-right">{currencyFormat(props.value)}</td>
        </tr>
    )
}



