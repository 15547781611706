import React from 'react'; 
import Pagination from '../amazon/pagination/Pagination';
import OrderBar from '../OrderReport/OrderBar';
import {UnmatchedAmazonOrderTableRow} from './UnmatchedAmazonOrderTableRow'; 

const UnmatchedAmazonOrderTable = (props) => {

    return(
        <div className="mb-5 text-capitalize">
        {props.orders && props.orders?.length === 0 && (
          <div className="text-center">Not orders matching</div>
        )}
        {props.orders && props.orders.length > 0 && (
          <React.Fragment>
            <div>
                <OrderBar
                  page={props.page}
                  size={props.size}
                  onPage={(e) => props.setPage(e)}
                  onSize={(e) => props.setSize(e)}
                  onChange={props.handlerChange}
                  onExport={props.handleExport}
                />
            </div>
            <div className="d-flex">
              <ul className="d-flex list-unstyled navigation-menu align-baseline align-items-end">
                <li className="d-flex" style={{ marginLeft: "12em" }}>
                  Order Id
                </li>
                <li style={{ marginLeft: "6.5em" }}>Purchase Local Date</li>
              </ul>
            </div>
            {props.orders.map((row) => (
              <UnmatchedAmazonOrderTableRow row={row} key={row['_id']} />
            ))}
            <Pagination
              totalElements={props.totalElements}
              totalPages={props.totalPages}
              page={props.page}
              onNext={props.handleNext}
              onPrev={props.handlePrev}
              onSelect={props.handleSelect}
            />
          </React.Fragment>
        )}
        
      </div>
    )
}

export default  UnmatchedAmazonOrderTable; 