import React from "react";
import Aux from "../../template/hoc/Aux_";

export default function PageTemplate({ title, children, account }) {
  return (
    <Aux>
      <div className="wrapper">
        <div className="container-fluid">
          <header className="justify-content-between pt-3">
            {account!="" && (
              <h2 className="font-22 my-3 ml-3">Account: {account}</h2>
            )}

            <div>
              <h3 className="font-16 my-3 ml-3 text-capitalize">{title}</h3>
            </div>
          </header>
          {children}
        </div>
      </div>
    </Aux>
  );
}
